import React, { useRef } from 'react';
import { Dropdown, Header } from 'semantic-ui-react';

// This is similar to the MultiSelectDropdown from the innercast-web/core package,
// but better accounts for single-selection cases.
const DropdownWithCategories = ({
    name,
    options = [],
    placeholder = 'Select',
    setValue = () => { },
    value,
    style = {
        paddingLeft: 30,
        minWidth: 170,
        backgroundColor: '#f8f8f8'
    },
    iconClass = 'chevron down',
    selectAll = false,
    disabled = false,
    onOpen = () => { },
    onClose = () => { },
    ...dropdownProps
}) => {
    const dropdownRef = useRef()

    let dropdownOptions = options
    if (options.length > 0 && options[0].header) {
        dropdownOptions = []

        options.forEach((item) => {
            // const dataItems = item.data.map((el) => el.value)
            // const filteredArray = dataItems.filter((x) =>
            //     value === x
            // )

            // if (filteredArray.length !== dataItems.length) {
                dropdownOptions.push({
                    type: 'header',
                    disabled: true,
                    key: item.header,
                    value: item.header,
                    content: (
                        <Header
                            content={item.header.toUpperCase()}
                            style={{
                                textDecoration: 'underline',
                                fontSize: 13
                            }}
                        />
                    ),
                })
            // }
            dropdownOptions = dropdownOptions.concat(item.data)
        })
    }

    return (
        <Dropdown
            ref={dropdownRef}
            name={name}
            options={dropdownOptions}
            placeholder={placeholder}
            clearable
            fluid
            selection
            search
            style={style}
            icon={
                <i
                    aria-hidden='true'
                    className={`${iconClass} icon`}
                    style={{
                        opacity: 1,
                        cursor: 'pointer',
                        pointerEvents: 'visible',
                        position: 'absolute',
                        right: 5,
                        top: 11,
                        zIndex: 3
                    }}
                />
            }
            onChange={(e, data) => {
                setValue(data.value)
            }}
            value={value}
            disabled={disabled || options.length === 0}
            onOpen={onOpen}
            onClose={onClose}
            {...dropdownProps}
        />
    )
};

export default (DropdownWithCategories);
