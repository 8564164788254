import React, { useEffect } from 'react'
import Flexbox from 'flexbox-react'
// import { Button } from 'semantic-ui-react'
import styles from './styles.css'

import ComposeView from '../../components/ComposeView'
import StreamView from '../../components/StreamView'
import { bindActionCreators } from 'redux'
import { TweeterActionCreators } from '../../redux/actions'
import { connect } from 'react-redux'
import { StoreCtx } from '../../Constants'

function TweeterHomeScreen({
    enableEmojiPicker = false,
    user = {
        uid: -1,
        fullName: '',
        profile_pic: '',
        groupID: 8
    },
    isAuthor = false,
    maxChirpLength = 280,
    ...props
}) {
    useEffect(() => {
        props.getMessages(false, 0);
        // props.getObservationStateStream(null, NUM_STREAM_FETCH);
    }, [])

    // const [streamType, setStreamType] = useState(1);

    // const stream_type_button_group = (
    //     <Button.Group style={{marginTop: "20px"}}>
    //         <Button
    //             active={streamType === 0}
    //             onClick={() => setStreamType(0)}>
    //             State Stream
    //         </Button>
    //         <Button
    //             active={streamType === 1}
    //             onClick={() => setStreamType(1)}>
    //             All Observations
    //         </Button>
    //     </Button.Group>
    // );

    return (
        <div className={styles.container}>
            <Flexbox flexDirection='column' className={styles.content}>
                {isAuthor && (
                    <ComposeView
                        enableEmojiPicker={enableEmojiPicker}
                        user={user}
                        maxChirpLength={maxChirpLength}
                        {...props}
                    />
                )}
                {/* {stream_type_button_group} */}
                <Flexbox flexDirection='row' justifyContent='center' style={{ margin: '15px 5px'}}>
                    <h3>All Observations</h3>
                </Flexbox>
                <StreamView
                    // messages={streamType === 1 ? props.messages : props.messages.stateStream}
                    // getMessages={streamType === 1 ? props.getMessages : props.getObservationStateStream}
                    // streamType={streamType}
                    messages={props.messages}
                    getMessages={props.getMessages}
                    streamType={1}
                />
            </Flexbox>
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(TweeterActionCreators, dispatch)
}

function mapStateToProps(state) {
    return {
        messages: state.messages
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    context: StoreCtx
})(TweeterHomeScreen)
