import * as types from "../actions/types";
import { createReducer } from "@linxsystems/web-core";

import { NUM_STREAM_FETCH } from "../../Constants";

export const messages = createReducer(
    {
        list: [],
        has_more_messages: false,
        last_loaded_message_id: 0,

        stateStream: {
            list: [],
            has_more_messages: false,
            last_loaded_message_id: 0,
        },
    }, {

    [types.SET_MESSAGES](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.list = action.request_old ? state.list.concat(action.data) : action.data;
            newState.has_more_messages = action.data.length === NUM_STREAM_FETCH;
            newState.last_loaded_message_id = action.data.length > 0 ? action.data[action.data.length - 1].id : 0;
        } else {
            newState.list = [];
        }
        return newState;
    },

    [types.SET_STATE_STREAM](state, action) {
        let newState = JSON.parse(JSON.stringify(state));
        if (action.stateStream !== null) {
            newState.stateStream.list = newState.stateStream.list.concat(action.stateStream);
            newState.stateStream.has_more_messages = action.stateStream.length === 20;
            newState.stateStream.last_loaded_message_id = action.stateStream.length > 0 ? action.stateStream[action.stateStream.length - 1].id : 0;
        } else {
            newState.stateStream = {
                list: [],
                has_more_messages: false,
                last_loaded_message_id: 0,
            };
        }
        return newState;
    },

});
