import {createStore, applyMiddleware, compose} from 'redux'

import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

import rootReducer from './reducers'

function configureStore(rootReducer) {

  const DEV = (process.env.NODE_ENV !== 'production')

  const logger = createLogger({
    collapsed: () => true
  })

  const middlewares = [thunkMiddleware, DEV && logger].filter(Boolean)

  const enhancer = compose(
    applyMiddleware(...middlewares),
  )
  return createStore(rootReducer, undefined, enhancer)
}

export const store = configureStore(rootReducer)
