import React from 'react'
import {
    THEME,
    getPrimaryThemeColor,
    getPrimaryLightThemeColor,
    getPrimaryLightestThemeColor
} from '@linxsystems/web-core'

export default function ComposeCameraSvg() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='25'
            viewBox='0 0 172 172'
        >
            <g
                fill='none'
                strokeLinecap='none'
                strokeMiterlimit='10'
                fontFamily='none'
                fontWeight='none'
                fontSize='none'
                textAnchor='none'
                style={{ mixBlendMode: 'normal' }}
            >
                <path d='M0 172V0h172v172z' />
                <path
                    d='M12.9 148.35a6.46 6.46 0 01-6.45-6.45V47.3c0-3.56 2.9-6.45 6.45-6.45h33.44l10.75-17.2h57.81l10.75 17.2h33.45c3.56 0 6.45 2.9 6.45 6.45v94.6c0 3.56-2.9 6.45-6.45 6.45z'
                    fill={getPrimaryLightestThemeColor(THEME())}
                />
                <path
                    d='M113.72 25.8l9.48 15.18 1.26 2.02h34.64c1.14 0 2.21.45 3.03 1.27.82.82 1.27 1.89 1.27 3.03v94.6a4.3 4.3 0 01-4.3 4.3H12.9a4.27 4.27 0 01-3.03-1.27 4.26 4.26 0 01-1.27-3.03V47.3a4.3 4.3 0 014.3-4.3h34.63l1.27-2.02 9.48-15.18h55.44m2.38-4.3H55.9L45.15 38.7H12.9a8.63 8.63 0 00-8.6 8.6v94.6c0 4.73 3.87 8.6 8.6 8.6h146.2c4.73 0 8.6-3.87 8.6-8.6V47.3c0-4.73-3.87-8.6-8.6-8.6h-32.25L116.1 21.5z'
                    fill={getPrimaryThemeColor(THEME())}
                />
                <path
                    d='M129 55.9h21.5v12.9H129zm-43-2.15a36.55 36.55 0 100 73.1 36.55 36.55 0 000-73.1z'
                    fill={getPrimaryLightThemeColor(THEME())}
                />
                <path
                    d='M86 55.9a34.44 34.44 0 0134.4 34.4A34.44 34.44 0 0186 124.7a34.44 34.44 0 01-34.4-34.4A34.44 34.44 0 0186 55.9m0-4.3a38.7 38.7 0 100 77.4 38.7 38.7 0 000-77.4zM34.4 43H21.5a4.3 4.3 0 010-8.6h12.9a4.3 4.3 0 010 8.6z'
                    fill={getPrimaryThemeColor(THEME())}
                />
                <path
                    d='M66.78 88.15a19.3 19.3 0 0117.07-17.07'
                    stroke='#fff'
                    strokeLinecap='round'
                />
            </g>
        </svg>
    )
}
