import React, {Component} from 'react'
import {Button} from 'semantic-ui-react'
import Flexbox from 'flexbox-react'

class LabelButton extends Component {

    static defaultProps = {
        className: 'gray-button',
        icon: null,
        onClick: () => {},
        label: ''
    }

    constructor(props) {
        super(props)
    }



    render() {

        return (
            <Flexbox flexDirection='column' justifyContent='center' alignItems='center'
                                     style={{marginBottom: '-9px', marginRight: '3.5px'}}>
                <Button icon={this.props.icon} className={this.props.className} onClick={this.props.onClick} style={{marginRight: '0px'}}/>
                <p style={{fontSize: '9px'}}>{this.props.label}</p>
            </Flexbox>
        )
    }
}

export default LabelButton
