export const AgMarket = {
  LightestPrimaryColor: '#98a9b9',
  LighterPrimaryColor: '#768ea2',
  LightPrimaryColor: '#55748c',
  PrimaryColor: '#325B77',
  DarkPrimaryColor: '#355168',
  DarkerPrimaryColor: '#2f485c',
  DarkestPrimaryColor: '#293e51',

  LightestSecondaryColor: '#797b83',
  LighterSecondaryColor: '#666871',
  LightSecondaryColor: '#53555f',
  SecondaryColor: '#40434E',
  DarkSecondaryColor: '#393c46',
  DarkerSecondaryColor: '#33353e',
  DarkestSecondaryColor: '#2c2e36'
}
