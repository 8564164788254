import React, { forwardRef, useEffect, useState, useRef } from "react";
import Flex from "flexbox-react";
import {
    Breadcrumb,
    Button,
    Dropdown,
    Icon,
    Input,
    Loader,
    Table,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { useMedia } from "@linxsystems/web-core";
import { CSVLink } from "react-csv";
import { CustomPagination } from "@linxsystems/web-core";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export default function BigDataView({
    categories,
    actions,
    labels,
    values,
    changeUserType,
    changeTimePeriod,
    timePeriod,
    customStartDate,
    setCustomStartDate,
    customEndDate,
    setCustomEndDate,
    activePage,
    totalPages,
    handlePageChange,
    loading,
    getValuesDataForCSV,
    exporting,
    setExporting,
    numRows,
    setNumRows,
}) {
    const [dataToDownload, setDataToDownload] = useState([]);
    const csvLink = useRef();

    useEffect(
        () => {
            // click the CSVLink component to trigger the CSV download
            if (dataToDownload && dataToDownload.length > 0) {
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 250);
            }
            setExporting(false);
        },
        // eslint-disable-next-line
        [dataToDownload],
    );

    const { category, action, label } = useParams();

    const renderAnalyticsTable = () => {
        if (!category) {
            // return category list
            return <CategoriesTable categories={categories} />;
        }
        if (category && !action) {
            // return actions list
            return <ActionsTable actions={actions} categoryName={category} />;
        } else if (action && !label) {
            return (
                <LabelsTable
                    labels={labels}
                    categoryName={category}
                    actionName={action}
                />
            );
        } else if (label && values) {
            if (loading) {
                return <Loader active indeterminate />;
            }
            return (
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    maxWidth="100%">
                    <CustomPagination
                        activePage={activePage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        numRows={numRows}
                        setNumRows={setNumRows}
                        showRowSelector={values.length > 0}
                    />
                    <ValuesTable values={values} />
                </Flex>
            );
        } else {
            return <div>oops! 404</div>;
        }
    };

    const userTypeOptions = [
        {
            key: "all",
            text: "All",
            value: "all",
        },
        {
            key: "internal",
            text: "Internal",
            value: "internal",
        },
        {
            key: "external",
            text: "External",
            value: "external",
        },
        {
            key: "unauthenticated",
            text: "Unauthenticated",
            value: "unauthenticated",
        },
    ];

    const timeRangeOptions = [
        {
            key: "1d",
            text: "Last 24 hours",
            value: "1d",
        },
        {
            key: "1w",
            text: "Last 7 days",
            value: "1w",
        },
        {
            key: "1m",
            text: "Last 30 days",
            value: "1m",
        },
        {
            key: "3m",
            text: "Last 3 months",
            value: "3m",
        },
        {
            key: "6m",
            text: "Last 6 months",
            value: "6m",
        },
        {
            key: "1y",
            text: "Last 12 months",
            value: "1y",
        },
        {
            key: "all",
            text: "All Time",
            value: "all",
        },
        {
            key: "custom",
            text: "Custom",
            value: "custom",
        },
    ];

    const downloadCSV = () => {
        setExporting(true);
        if (!category && categories.length > 0) {
            // return category list
            const cats = [Object.keys(categories[0])].concat(
                categories.map(Object.values),
            );
            setDataToDownload(cats);
        } else if (category && !action && actions.length > 0) {
            // return actions list
            const acts = [Object.keys(actions[0])].concat(
                actions.map(Object.values),
            );
            setDataToDownload(acts);
        } else if (action && !label && labels.length > 0) {
            const lbls = [Object.keys(labels[0])].concat(
                labels.map(Object.values),
            );
            setDataToDownload(lbls);
        } else if (label && values && values.length > 0) {
            getValuesDataForCSV((successful, data) => {
                if (successful) {
                    setDataToDownload(data);
                } else {
                    setDataToDownload([]);
                }
            });
        } else {
            setDataToDownload([]);
        }
    };

    const CustomDatePickerInput = forwardRef(({ onClick }, ref) => {
        return (
            <Input
                ref={ref}
                placeholder="Time Frame"
                style={{ minWidth: 210, cursor: "pointer" }}
                onClick={onClick}
                value={
                    customStartDate !== null && customEndDate !== null ? `${format(customStartDate, "M/d/yy")} - ${format(customEndDate, "M/d/yy")}` : ""
                }
                icon={
                    <i
                        aria-hidden="true"
                        className="caret down icon"
                        style={{
                            opacity: 1,
                            cursor: "pointer",
                            pointerEvents: "all",
                        }}
                        onClick={onClick}
                    />
                }
            />
        );
    });

    const onCustomDateChange = (dates) => {
        const [start, end] = dates;
        setCustomStartDate(start === null ? start : new Date(start.setHours(0,0,0,0)));
        setCustomEndDate(end === null ? end : new Date(end.setHours(23,59,59,999)));
    };

    return (
        <Flex
            flexGrow={1}
            flexDirection="column"
            style={{
                padding: 20,
                backgroundColor: "#F8F8F8",
                minHeight: "100%",
            }}>
            <Flex
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                style={{ marginBottom: 18 }}>
                <h1>Events</h1>
                <Flex flexDirection="row" alignItems="center" flexWrap="wrap">
                    <Flex
                        alignItems="center"
                        style={{ whiteSpace: "nowrap", marginRight: 20 }}>
                        <span style={{ color: "#6A6A6A", marginRight: 5 }}>
                            User Type:
                        </span>
                        <Dropdown
                            selection
                            basic
                            defaultValue={userTypeOptions[0].value}
                            options={userTypeOptions}
                            style={{ width: "150px", minWidth: "150px" }}
                            onChange={changeUserType}
                        />
                    </Flex>
                    <Flex alignItems="center" style={{ whiteSpace: "nowrap", marginRight: timePeriod === 'custom' ? 20 : 0 }}>
                        <span style={{ color: "#6A6A6A", marginRight: 5 }}>
                            Time:
                        </span>
                        <Dropdown
                            selection
                            basic
                            defaultValue={timeRangeOptions[2].value}
                            options={timeRangeOptions}
                            style={{ width: "150px", minWidth: "150px" }}
                            onChange={changeTimePeriod}
                        />
                    </Flex>
                    {timePeriod === 'custom' && <Flex alignItems="center">
                        <span style={{ color: "#6A6A6A", marginRight: 5 }}>
                            Select Range:
                        </span>
                        <DatePicker
                            calendarClassName="bigDataDatepicker"
                            customInput={<CustomDatePickerInput />}
                            startDate={customStartDate}
                            endDate={customEndDate}
                            selectsRange
                            onChange={onCustomDateChange}
                            maxDate={new Date()}
                        />
                    </Flex>}
                </Flex>
            </Flex>

            <Flex
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row">
                <Breadcrumbs />
                <div>
                    <Button
                        onClick={downloadCSV}
                        basic
                        icon
                        labelPosition="right"
                        style={{ marginRight: 0 }}
                        loading={exporting}>
                        <Icon name="download" />
                        Export
                    </Button>
                </div>
            </Flex>

            <Flex
                flexDirection="column"
                style={{
                    padding: 20,
                    backgroundColor: "white",
                    borderRadius: 5,
                    boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
                    marginTop: 20,
                }}>
                {renderAnalyticsTable()}
            </Flex>

            <CSVLink
                data={dataToDownload}
                filename="big-data.csv"
                className="hidden"
                ref={csvLink}
                target="_blank"
            />
        </Flex>
    );
}

function Breadcrumbs() {
    const { category, action, label } = useParams();
    const categorySanitized = decodeURIComponent(category);
    const actionSanitized = decodeURIComponent(action);
    const labelSanitized = decodeURIComponent(label);

    const history = useHistory();

    return (
        <Breadcrumb>
            <Breadcrumb.Section
                link
                onClick={() => history.push(`/hidden/analytics/bigdata/`)}
                active={!category}>
                All
            </Breadcrumb.Section>
            {category && (
                <React.Fragment>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section
                        link
                        onClick={() =>
                            history.push(`/hidden/analytics/bigdata/${category}`)
                        }
                        active={!action}>
                        {categorySanitized}
                    </Breadcrumb.Section>
                </React.Fragment>
            )}
            {action && (
                <React.Fragment>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section
                        link
                        onClick={() =>
                            history.push(
                                `/hidden/analytics/bigdata/${category}/${action}`,
                            )
                        }
                        active={!label}>
                        {actionSanitized}
                    </Breadcrumb.Section>
                </React.Fragment>
            )}
            {label && (
                <React.Fragment>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section active>
                        {labelSanitized}
                    </Breadcrumb.Section>
                </React.Fragment>
            )}
        </Breadcrumb>
    );
}

function CategoriesTable({ categories }) {
    const history = useHistory();

    const navigateToCategory = (categoryName) => {
        const encodedCategoryName = encodeURIComponent(categoryName);
        history.push(`/hidden/analytics/bigdata/${encodedCategoryName}`);
    };

    return (
        <Table singleLine unstackable selectable basic="very">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Count</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {categories.map((category, index) => (
                    <Table.Row
                        key={index}
                        onClick={() => navigateToCategory(category.name)}>
                        <Table.Cell>{category.name}</Table.Cell>
                        <Table.Cell>{category.num_events}</Table.Cell>
                        <Table.Cell>{category.description}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
}

function ActionsTable({ categoryName, actions }) {
    const history = useHistory();

    const navigateToAction = (actionName) => {
        const encodedActionName = encodeURIComponent(actionName);
        history.push(`/hidden/analytics/bigdata/${categoryName}/${encodedActionName}`);
    };

    return (
        <Table singleLine unstackable selectable basic="very">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Count</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {actions.map((action, index) => (
                    <Table.Row
                        key={index}
                        onClick={() => navigateToAction(action.name)}>
                        <Table.Cell>{action.name}</Table.Cell>
                        <Table.Cell>{action.num_events}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
}

function LabelsTable({ categoryName, actionName, labels }) {
    const history = useHistory();

    const navigateToLabel = (label) => {
        const encodedLabel = encodeURIComponent(label);
        history.push(
            `/hidden/analytics/bigdata/${categoryName}/${actionName}/${encodedLabel}`,
        );
    };

    return (
        <Table singleLine unstackable selectable basic="very">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Count</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {labels.map((label, index) => (
                    <Table.Row
                        key={index}
                        onClick={() => navigateToLabel(label.label)}>
                        <Table.Cell>{label.label}</Table.Cell>
                        <Table.Cell>{label.num_events}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
}

function ValuesTable({ values = [] }) {
    const useFullDate = useMedia([`(max-width: 1000px)`], [false], true);
    const showSource = useMedia([`(max-width: 575px)`], [false], true);
    const showGroup = useMedia([`(max-width: 475px)`], [false], true);
    const showCircle = useMedia([`(max-width: 1500px)`], [false], true);

    return (
        <Table singleLine unstackable basic="very" striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Value</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>User</Table.HeaderCell>
                    {showGroup && <Table.HeaderCell>Group</Table.HeaderCell>}
                    {showCircle && <Table.HeaderCell>Circle</Table.HeaderCell>}
                    {showSource && <Table.HeaderCell>Source</Table.HeaderCell>}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {values.map((val, index) => {
                    let date = val.date;
                    if (date) {
                        const tmp = new Date(date);
                        if (useFullDate) {
                            date = format(tmp, "yyyy-MM-dd HH:mm:ss");
                        } else {
                            date = format(tmp, "MMM dd");
                        }
                    }

                    return (
                        <Table.Row key={index}>
                            <Table.Cell>{val.value}</Table.Cell>
                            <Table.Cell>{date}</Table.Cell>
                            <Table.Cell>{val.user_name}</Table.Cell>
                            {showGroup && (
                                <Table.Cell>{val.user_group}</Table.Cell>
                            )}
                            {showCircle && (
                                <Table.Cell>{val.user_circle}</Table.Cell>
                            )}
                            {showSource && (
                                <Table.Cell>{val.source}</Table.Cell>
                            )}
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
}
