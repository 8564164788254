import React, { memo, useRef } from 'react'
import { Dropdown, Header } from 'semantic-ui-react'
import classnames from '../styles/MultiselectDropdown.css'

const MultiSelectDropdown = ({
  name,
  options = [],
  placeholder = 'Select',
  setValues = () => { },
  values = [],
  style = {
    paddingLeft: 30,
    minWidth: 170,
    backgroundColor: '#f8f8f8'
  },
  iconClass = 'chevron down',
  selectAll = false,
  clearable = true,
  onOpen = () => { },
  onClose = () => { },
  ...dropdownProps
}) => {
  const dropdownRef = useRef()

  let dropdownOptions = options
  if (options.length > 0 && options[0].header) {
    dropdownOptions = []

    options.forEach((item) => {
      const dataItems = item.data.map((el) => el.value)
      const filteredArray = dataItems.filter((value) =>
        values.includes(value)
      )

      if (filteredArray.length !== dataItems.length) {
        dropdownOptions.push({
          type: 'header',
          disabled: true,
          key: item.header,
          value: item.header,
          content: (
            <Header
              content={item.header.toUpperCase()}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: 13
              }}
            />
          ),
          onClick: () => {
            let vals = [].concat(values)
            dataItems.forEach(
              (el) => !vals.includes(el) && vals.push(el)
            )
            setValues(vals)
            if (
              vals.length === justOptions.length &&
              dropdownRef?.current?.close
            ) {
              dropdownRef.current.close()
            }
          }
        })
      }
      dropdownOptions = dropdownOptions.concat(item.data)
    })
  }

  const justOptions = dropdownOptions.filter((el) => el.type !== 'header')
  const allSelected = values.length === justOptions.length

  return (
    <Dropdown
      ref={dropdownRef}
      className={
        values.length !== justOptions.length
          ? classnames.multiselect_dropdown
          : classnames.all_selected
      }
      name={name}
      options={dropdownOptions}
      placeholder={placeholder}
      clearable={clearable}
      fluid
      multiple
      selection
      search
      style={style}
      header={
        selectAll && values.length !== justOptions.length && (
          <Header
            className={classnames.select_all}
            content='Select All'
            style={{
              textAlign: 'center',
              textDecoration: 'underline',
              cursor: 'pointer',
              margin: 0,
              padding: 10,
              backgroundColor: '#e0e0e0',
              fontSize: 13
            }}
            onClick={() => {
              const vals = justOptions.map((item) => item.value)
              setValues(vals)
              if (dropdownRef?.current?.close) {
                dropdownRef.current.close()
              }
            }}
          />
        )
      }
      icon={
        <i
          aria-hidden='true'
          className={`${iconClass} icon`}
          style={{
            opacity: 1,
            cursor: 'pointer',
            pointerEvents: 'visible',
            position: 'absolute',
            right: 5,
            top: 11,
            zIndex: 3
          }}
        />
      }
      onChange={(e, data) => {
        setValues(data.value)
        if (
          data.value.length === justOptions.length &&
          dropdownRef?.current?.close
        ) {
          dropdownRef.current.close()
        }
      }}
      value={values}
      renderLabel={(item, i, labelProps) => {
        const value = labelProps.value
        if (allSelected) {
          return i === 0
            ? {
              content: `All ${name}`,
              onRemove: () => setValues([])
            }
            : null
        }
        return {
          content: value
        }
      }}
      disabled={options.length === 0}
      onOpen={onOpen}
      onClose={onClose}
      {...dropdownProps}
    />
  )
};

export default memo(MultiSelectDropdown)
