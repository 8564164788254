import createReducer from '../../lib/createReducer'
import * as types from '../actions/types'

export const core = createReducer(
  {
    endpoints: null,
    host: null,
    theme: 'innercast',
    authGroup: null,
    basename: '/',
    appName: 'Innercast',
    debug: false
  },
  {
    [types.SET_ENDPOINT_URLS](state, action) {
      let newState = Object.assign({}, state)
      if (action.info !== null) {
        newState.endpoints = action.info
      } else {
        newState.endpoints = null
      }
      return newState
    },

    [types.SET_BASENAME](state, action) {
      let newState = Object.assign({}, state)
      if (action.basename !== null) {
        newState.basename = action.basename
      } else {
        newState.basename = null
      }
      return newState
    },

    [types.SET_APPNAME](state, action) {
      let newState = Object.assign({}, state)
      if (action.appName !== null) {
        newState.appName = action.appName
      } else {
        newState.appName = 'Innercast'
      }
      return newState
    },

    [types.SET_HOST](state, action) {
      let newState = Object.assign({}, state)
      if (action.host !== null) {
        newState.host = action.host
      } else {
        newState.host = null
      }
      return newState
    },

    [types.SET_AUTH_GROUP](state, action) {
      let newState = Object.assign({}, state)
      if (action.authGroup !== null) {
        newState.authGroup = action.authGroup
      } else {
        newState.authGroup = null
      }
      return newState
    },

    [types.SET_THEME](state, action) {
      let newState = Object.assign({}, state)
      if (action.theme !== null) {
        newState.theme = action.theme
      } else {
        newState.theme = 'innercast'
      }
      return newState
    },

    [types.SET_DEBUG](state, action) {
      let newState = Object.assign({}, state)
      if (action.debug !== null) {
        newState.debug = action.debug
      } else {
        newState.debug = false
      }
      return newState
    }
  }
)
