import React, { useState, useEffect } from "react";
import BigDataView from "../views/BigDataView";

import * as ActionCreators from "../redux/actions/analytics";
import { StoreCtx } from "../Constants";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import { UserType } from "../redux/actions/analytics";

function BigDataScreen(props) {
    const [categories, setCategories] = useState([]);
    const [actions, setActions] = useState([]);
    const [labels, setLabels] = useState([]);
    const [values, setValues] = useState([]);

    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [numRows, setNumRows] = useState(20);
    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);

    const [userType, setUserType] = useState("all");
    const [timePeriod, setTimePeriod] = useState("1m");
    const [customStartDate, setCustomStartDate] = useState(new Date());
    const [customEndDate, setCustomEndDate] = useState(null);

    const { category, action, label } = useParams();
    const categoryDecoded = decodeURIComponent(category);
    const actionDecoded = decodeURIComponent(action);
    const labelDecoded = decodeURIComponent(label);

    useDeepCompareEffect(() => {
        refreshData();
    }, [
        categories,
        actions,
        labels,
        category,
        action,
        label,
        activePage,
        numRows,
    ]);

    useEffect(
        () => {
            props.getCategories(
                didGetCategories,
                selectedUserType(),
                startingTime(),
                endingTime(),
            );
        },
        // eslint-disable-next-line
        [userType, timePeriod, customEndDate],
    );

    const changeUserType = (event, data) => {
        setUserType(data.value);
    };
    const changeTimePeriod = (event, data) => {
        setTimePeriod(data.value);
    };

    const refreshData = () => {
        if (!categories || categories.length === 0) {
            return;
        }

        // every time the category, action, or label change, update data
        if (!category) {
            setActions([]);
        }
        if (!action) {
            setLabels([]);
        }
        if (!label) {
            setValues([]);
        }

        let categoryID;
        if (categories && categories.length > 0) {
            const matches = categories.filter(
                (cat) => cat.name === categoryDecoded,
            );
            if (matches && matches.length === 1) {
                categoryID = matches[0].id;
            }
        }
        let actionID;
        if (actions && actions.length > 0) {
            const matches = actions.filter((act) => act.name === actionDecoded);
            if (matches && matches.length === 1) {
                actionID = matches[0].id;
            }
        }

        if (categoryID) {
            if (label && actionID) {
                setLoading(true);
                props.getValuesForCategoryAndActionAndLabel(
                    didGetValues,
                    categoryID,
                    actionID,
                    labelDecoded,
                    activePage,
                    numRows,
                    selectedUserType(),
                    startingTime(),
                    endingTime(),
                );
            } else if (action && actionID) {
                props.getLabelsForCategoryAndAction(
                    didGetLabels,
                    categoryID,
                    actionID,
                    selectedUserType(),
                    startingTime(),
                    endingTime(),
                );
            } else if (category) {
                props.getActionsForCategories(
                    didGetActions,
                    categoryID,
                    selectedUserType(),
                    startingTime(),
                    endingTime(),
                );
            }
        }
    };

    const selectedUserType = () => {
        switch (userType) {
            case "all":
                return UserType.All;
            case "internal":
                return UserType.Internal;
            case "external":
                return UserType.External;
            case "unauthenticated":
                return UserType.Unauthenticated;
            default:
                return UserType.All;
        }
    };

    const startingTime = () => {
        const now = new Date().getTime();
        const day = 86400 * 1000;
        switch (timePeriod) {
            case "1d":
                return new Date(now - day).toISOString();
            case "1w":
                return new Date(now - 7 * day).toISOString();
            case "1m":
                return new Date(now - 30 * day).toISOString();
            case "3m":
                return new Date(now - 90 * day).toISOString();
            case "6m":
                return new Date(now - 183 * day).toISOString();
            case "1y":
                return new Date(now - 365 * day).toISOString();
            case "all":
                return new Date("11/01/2020").toISOString();
            case "custom":
                return customStartDate;
        }
    };

    const endingTime = () => {
        switch (timePeriod) {
            case "custom":
                return customEndDate;
            default:
                return new Date();
        }
    };

    const didGetCategories = (successful, message, data) => {
        if (successful) {
            setCategories(data);
            refreshData();
        } else {
            setCategories([]);
        }
    };

    const didGetActions = (successful, message, data) => {
        if (successful) {
            setActions(data);
        } else {
            setActions([]);
        }
    };

    const didGetLabels = (successful, message, data) => {
        if (successful) {
            setLabels(data);
        } else {
            setLabels([]);
        }
    };

    const didGetValues = (successful, message, data, total_pages) => {
        if (successful) {
            setValues(data);
            setTotalPages(total_pages);
        } else {
            setValues([]);
        }
        setLoading(false);
    };

    const handlePageChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    const getValuesDataForCSV = (callback = () => {}) => {
        let categoryID;
        if (categories && categories.length > 0) {
            const matches = categories.filter(
                (cat) => cat.name === categoryDecoded,
            );
            if (matches && matches.length === 1) {
                categoryID = matches[0].id;
            }
        }
        let actionID;
        if (actions && actions.length > 0) {
            const matches = actions.filter((act) => act.name === actionDecoded);
            if (matches && matches.length === 1) {
                actionID = matches[0].id;
            }
        }

        if (categoryID && label && actionID) {
            props.getValuesDataForCSV(
                callback,
                categoryID,
                actionID,
                labelDecoded,
                selectedUserType(),
                startingTime(),
                endingTime(),
            );
        }
    };

    return (
        <BigDataView
            categories={categories}
            actions={actions}
            labels={labels}
            values={values}
            changeUserType={changeUserType}
            changeTimePeriod={changeTimePeriod}
            timePeriod={timePeriod}
            customStartDate={customStartDate}
            setCustomStartDate={setCustomStartDate}
            customEndDate={customEndDate}
            setCustomEndDate={setCustomEndDate}
            activePage={activePage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            loading={loading}
            getValuesDataForCSV={getValuesDataForCSV}
            exporting={exporting}
            setExporting={setExporting}
            numRows={numRows}
            setNumRows={setNumRows}
        />
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    context: StoreCtx,
})(BigDataScreen);
