import React, { useRef, useState } from 'react'
import Flexbox from 'flexbox-react'
import { Button, Form, Icon, Popup, TextArea } from 'semantic-ui-react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { isIE } from 'react-device-detect'

import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'

// import HashtagDropdown from './HashtagDropdown'
import EmojiSvg from './EmojiSvg'
// import CropSvg from './CropSvg'
import ComposeCameraSvg from './ComposeCameraSvg'
import styles from './styles/ComposeView.css'
import {
  THEME,
  getPrimaryLightThemeColor,
  getPrimaryThemeColor,
  InnercastCoreCDNBase,
  InnercastAvatar,
  UploadProfilePicture,
  // HashtagAnalyticsModal,
  TaggingModal,
} from '@linxsystems/web-core'

const ChirpButton = ({ ...props }) => {
  if (!isIE) {
    const styled = require('styled-components').default
    const ChirpButtonJSX = styled(Button)`
            background-color: ${() => getPrimaryThemeColor(THEME())} !important;
            color: white !important;
            padding-left: 40px !important;
            padding-right: 40px !important;
            margin-right: 10px !important;

            &:hover {
                background-color: ${() =>
        getPrimaryLightThemeColor(THEME())} !important;
            }
        `
    return <ChirpButtonJSX {...props} />
  }

  return (
    <Button
      className={styles.chirp_button}
      {...props}
      style={{
        background: `${getPrimaryThemeColor(THEME())} !important`
      }}
    />
  )
}

export default function ComposeView({
  enableEmojiPicker = false,
  maxChirpLength = 280,
  user = {
    fullName: '',
    profile_pic: '',
    uid: -1
  },
  changeMyDetails = () => { },
  createChirpWithFocusTag = () => { },
  createObservationNoPostV4 = () => { },
  didFinish = () => { },
  getMessages = () => { },
  getComposeDropdownList = () => { },
}) {
  const [message, setMessage] = useState('')
  const [imageFile, setImageFile] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const [showAvatarEditor, setShowAvatarEditor] = useState(false)

  const MAX_CHIRP_LENGTH = maxChirpLength
  const filepicker = useRef()

  const [hashtagAnalyticsModalOpen, setHashtagAnalyticsModalOpen] = useState(false);

  const handleChange = (e) => {
    const message = e.target.value
    setMessage(message)
  }

  const _openImagePicker = () => {
    if (filepicker?.current) {
      filepicker.current.click()
    }
  }

  const _handleImagePicker = (event) => {
    if (event.target.files.length > 0) {
      setImageFile(event.target.files[0])
      setImagePreview(URL.createObjectURL(event.target.files[0]))
    }
  }

  const _removeAttachedImage = (evt) => {
    evt.stopPropagation()
    evt.preventDefault()
    setImageFile(null)
    setImagePreview(null)
  }

  // const openHashtagAnalyticsModal = () => {
  //   setHashtagAnalyticsModalOpen(true)
  // };

  const submitChirpDraft = () => {
    setIsCreating(true);
    createObservationNoPostV4(
      message,
      imageFile,
      null,
      createMessageSuccess
    );
  };

  const createMessage = (for_microsite = false, states, districts, regions, content_category_id, focus_crop_id, focus_product_id, is_customer_exclusive, createMessageSuccess) => {
    setIsCreating(true)
    createChirpWithFocusTag(
      message,
      imageFile,
      null,
      states,
      districts,
      regions,
      content_category_id,
      focus_crop_id,
      focus_product_id,
      is_customer_exclusive,
      createMessageSuccess
    )
  }

  // const getKeyArray = (selectedValues, options) => {
  //   let allOptions = options
  //   if (options.length > 0 && options[0].header) {
  //     allOptions = []
  //     options.forEach((item) => {
  //       allOptions = allOptions.concat(item.data)
  //     })
  //   }

  //   let keyArray = selectedValues.map(
  //     (val) => allOptions.find((el) => el.value === val).key
  //   )

  //   return keyArray
  // }

  const createMessageSuccess = (successful) => {
    if (successful) {
      if (filepicker?.current) {
        filepicker.current.value = ''
      }

      setIsCreating(false)
      setImageFile(null)
      setImagePreview(null)
      setMessage('')

      const audioEl = document.getElementsByClassName('audio-element')[0]
      audioEl.play()
      getMessages(false, 0)
    } else {
      setIsCreating(false)
    }

    didFinish(successful);
  }

  const openAvatarEditor = () => {
    setShowAvatarEditor(true)
  }

  const addEmoji = (emoji) => {
    setMessage(message + emoji.native)
  }

  const renderImagePreview = () => {
    if (imageFile === null) {
      return null
    }

    return (
      <div className={styles.previewBox} onClick={_openImagePicker}>
        <Icon
          name='delete'
          size='small'
          className={styles.removeImageAttachement}
          circular
          inverted
          onClick={_removeAttachedImage}
          title='Remove Attachment'
          color='red'
        />
        <img
          src={imagePreview}
          alt={'Selected Media to Upload'}
          className={styles.imagePreview}
        />
      </div>
    )
  }

  // const renderCategorizationTools = () => (
  //   <Flexbox
  //     style={{ paddingTop: 20, paddingBottom: 10 }}
  //     justifyContent='center'
  //     flexWrap='wrap'
  //   >
  //     <HashtagDropdown
  //       name='States'
  //       placeholder='Select State'
  //       options={stateOptions}
  //       setValues={setSelectedStates}
  //       values={selectedStates}
  //       leftIcon={
  //         <i
  //           aria-hidden='true'
  //           className='map marker alternate icon'
  //         />
  //       }
  //     />
  //     <HashtagDropdown
  //       name='Crops'
  //       placeholder='Select Crop'
  //       options={cropOptions}
  //       setValues={setSelectedCrops}
  //       values={selectedCrops}
  //       leftIcon={
  //         <div
  //           style={{
  //             position: 'absolute',
  //             top: 7,
  //             left: 9
  //           }}
  //         >
  //           <CropSvg />
  //         </div>
  //       }
  //     />
  //     <HashtagDropdown
  //       name='Products'
  //       placeholder='Select Product'
  //       options={productOptions}
  //       setValues={setSelectedProducts}
  //       values={selectedProducts}
  //       leftIcon={<i aria-hidden='true' className='tag icon' />}
  //     />
  //   </Flexbox>
  // )

  let message_length = message.length
  let text_value
  let path_color
  let text_color
  let progress_width_height

  if (MAX_CHIRP_LENGTH - message_length > 20) {
    text_value = ''
    path_color = 'rgb(33,131,199)'
    text_color = 'rgb(80,80,80)'
    progress_width_height = '25px'
  } else if (
    MAX_CHIRP_LENGTH - message_length <= 20 &&
    MAX_CHIRP_LENGTH - message_length > 0
  ) {
    text_value = MAX_CHIRP_LENGTH - message_length
    path_color = 'rgb(255,173,31)'
    text_color = 'rgb(80,80,80)'
    progress_width_height = '40px'
  } else if (MAX_CHIRP_LENGTH - message_length == 0) {
    text_value = '0'
    path_color = 'rgb(224,36,94)'
    text_color = 'rgb(224,36,94)'
    progress_width_height = '40px'
  } else if (MAX_CHIRP_LENGTH - message_length < 0) {
    text_value = MAX_CHIRP_LENGTH - message_length
    path_color = 'rgb(224,36,94)'
    text_color = 'rgb(224,36,94)'
    progress_width_height = '40px'
  } else {
    text_value = MAX_CHIRP_LENGTH - message_length
    path_color = 'rgb(33,131,199)'
    text_color = 'rgb(80,80,80)'
    progress_width_height = '40px'
  }

  return (
    <div className={styles.composeContainer}>
      <Form className={styles.form}>
        {/*{renderCategorizationTools()}*/}
        <Flexbox flexDirection='row'>
          <div className={styles.avatar}>
            <InnercastAvatar
              src={user.profile_pic}
              size='75'
              name={user.fullName}
              onClick={openAvatarEditor}
            />
          </div>

          <div style={{ width: '100%' }}>
            <TextArea
              value={message}
              placeholder="What's happening?"
              onChange={handleChange}
              className={styles.textarea}
            />
            {renderImagePreview()}
          </div>
        </Flexbox>

        <input
          ref={filepicker}
          type='file'
          style={{ display: 'none' }}
          accept='image/png, image/jpeg'
          onChange={_handleImagePicker}
        />
        <Flexbox
          className={styles.locationBlock}
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Flexbox flexDirection='row' alignItems='center'>
            <div
              onClick={_openImagePicker}
              className={styles.filePicker}
            >
              <ComposeCameraSvg />
            </div>

            {enableEmojiPicker && (
              <Popup
                on='click'
                trigger={
                  <div
                    style={{
                      cursor: 'pointer',
                      marginLeft: 10,
                      marginRight: 10
                    }}
                  >
                    <EmojiSvg />
                  </div>
                }
              >
                <Picker
                  onSelect={addEmoji}
                  showSkinTones={false}
                  showPreview={false}
                />
              </Popup>
            )}
          </Flexbox>
          <Flexbox flexDirection='row' alignItems='center'>
            <CircularProgressbar
              value={message_length}
              maxValue={MAX_CHIRP_LENGTH}
              text={text_value}
              background={message_length > MAX_CHIRP_LENGTH}
              styles={{
                root: {
                  width: progress_width_height,
                  height: progress_width_height,
                  marginRight: '10px',
                  transition:
                    'width 500ms, height 500ms ease-in-out'
                },
                background: { fill: 'rgba(255,0,0,0.3)' },
                path: { stroke: path_color },
                text: { fill: text_color, fontSize: '35px' }
              }}
            />
            <ChirpButton
              disabled={
                message_length <= 0 ||
                message_length > MAX_CHIRP_LENGTH
                || !imageFile
                // selectedStates.length === 0 ||
                // selectedCrops.length === 0 ||
                // selectedProducts.length === 0
              }
              loading={isCreating}
              // onClick={openHashtagAnalyticsModal}
              onClick={submitChirpDraft}
              size='large'
              // content='Post'
              content='Submit Draft'
            />
            <div>
              <audio className='audio-element'>
                <source
                  src={
                    InnercastCoreCDNBase +
                    '/chirpsound/Chick chirp 01_SFXBible_ss00116.mp3'
                  }
                />
              </audio>
            </div>
          </Flexbox>
        </Flexbox>
      </Form>
      <UploadProfilePicture
        prof_pic_modal_open={showAvatarEditor}
        close={() => setShowAvatarEditor(false)}
        uid={user.uid}
        user={user}
        changeMyDetails={changeMyDetails}
        refreshWholePage={getMessages}
      />
      <TaggingModal
        modalOpen={hashtagAnalyticsModalOpen}
        closeModal={() => { setHashtagAnalyticsModalOpen(false) }}
        publish={createMessage}
        publishCallback={createMessageSuccess}
        getComposeDropdownList={getComposeDropdownList}
        token={'asdf'}
        user={user}
      />
    </div>
  )
}
