import * as CoreActions from './core'
import * as MediaActions from './media'
import * as MessageActions from './messages'
import * as PostAnalyticsActions from './post_analytics'

export const ActionCreators = Object.assign(
  {},
  CoreActions,
  MediaActions,
  MessageActions,
  PostAnalyticsActions
)
