import React, { useEffect, useRef, useState } from 'react'
import Linkify from 'react-linkify'
import { formatDistanceStrict, isAfter, format } from 'date-fns'
import Flexbox from 'flexbox-react'
import { LinkPreview } from '@dhaiwat10/react-link-preview'
import {
    InnercastAvatar,
    userClickImage,
    userLikePost,
    userUnlikePost
} from '@linxsystems/web-core'

import styles from '../screens/TweeterHomeScreen/styles.css'
import MessageLikeButton from './MessageLikeButton'
import MessageDeleteButton from './MessageDeleteButton'
import TweetImage from './TweetImage'

export default function Tweet({
    dashboardTweet = false,
    tweetStyle = {},
    ...props
}) {
    const microlinksRef = useRef(null)
    const [microlinks, setMicrolinks] = useState([])

    useEffect(() => {
        if (microlinksRef.current) {
            setMicrolinks([].concat(microlinksRef.current))
        } else {
            setMicrolinks([])
        }
    }, [props.message])

    const microlinkifyUrl = (text) => {
        let tempArray = microlinksRef.current
            ? [].concat(microlinksRef.current)
            : []
        if (
            !tempArray.includes(text) &&
            !tempArray.includes('https://' + text)
        ) {
            if (text.includes('http')) {
                tempArray.push(text)
            } else {
                tempArray.push('https://' + text)
            }

            microlinksRef.current = tempArray
        }
        return text
    }

    const renderMicroLinkPreview = () => {
        return microlinks.reverse().map((item, index) => {
            return (
                <Flexbox
                    flexDirection='row'
                    alignItems='center'
                    key={index}
                    style={{ marginTop: '10px' }}
                >
                    <LinkPreview
                        url={item}
                        width='100%'
                        height='100%'
                        fetcher={async (url) => {
                            const response = await fetch(
                                `https://rlp-proxy.herokuapp.com/v2?url=${url}`
                            )
                            const json = await response.json()
                            if (response.status === 404) {
                                const temp = [].concat(microlinks)
                                const microlink_urls = temp.filter(
                                    (_url) => _url !== url
                                )
                                setMicrolinks(microlink_urls)
                            }
                            return json.metadata
                        }}
                    />
                </Flexbox>
            )
        })
    }

    let date_created = new Date(props.message.date_created)
    let today = new Date()
    let dateStr
    let yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    if (isAfter(date_created, yesterday)) {
        dateStr = formatDistanceStrict(date_created, today)
        dateStr = dateStr.replace(' minutes', 'm')
        dateStr = dateStr.replace(' minute', 'm')
        dateStr = dateStr.replace(' seconds', 's')
        dateStr = dateStr.replace(' second', 's')
        dateStr = dateStr.replace(' hours', 'h')
        dateStr = dateStr.replace(' hour', 'h')
    } else {
        if (date_created.getFullYear() === today.getFullYear()) {
            dateStr = format(date_created, 'LLL d')
        } else {
            dateStr = format(date_created, 'LLL d, y')
        }
    }

    const classNames = dashboardTweet
        ? [styles.tweet, styles.dashboardTweet, styles.dontBreakOut]
        : [styles.tweet, styles.dontBreakOut]

    return (
        <div className={classNames.join(' ')} style={tweetStyle}>
            <Flexbox flexDirection='row' style={{ height: '100%' }}>
                <Flexbox className={styles.avatar}>
                    <InnercastAvatar
                        src={props.message.created_by_user_profile_pic}
                        size='50'
                        name={props.message.created_by_user_name}
                    />
                </Flexbox>
                <Flexbox
                    flexDirection='column'
                    justifyContent={'space-between'}
                    flexGrow={1}
                    style={{ width: 50, height: '100%' }}
                >
                    <Flexbox flexDirection='column'>
                        <Flexbox
                            flexDirection='row'
                            alignItems='center'
                            style={{ marginBottom: '5px' }}
                        >
                            <strong>
                                {props.message.created_by_user_name}
                            </strong>
                            <p style={{ color: 'grey', marginLeft: '5px' }}>
                                {' '}
                                · {dateStr}
                            </p>
                        </Flexbox>

                        <Linkify textDecorator={microlinkifyUrl}>
                            <span style={{ whiteSpace: 'pre-wrap' }}>
                                {props.message.message}
                            </span>
                        </Linkify>

                        {props.message.media_url !== undefined &&
                            props.message.media_url !== null &&
                            props.message.media_url.length > 0 && (
                                <TweetImage
                                    media_url={props.message.media_url}
                                    media_small_url={
                                        props.message.media_small_url
                                    }
                                    media_medium_url={
                                        props.message.media_medium_url
                                    }
                                    media_large_url={
                                        props.message.media_large_url
                                    }
                                    post_id={props.message.post_id}
                                    userClickImage={userClickImage}
                                />
                            )}
                        {renderMicroLinkPreview()}
                    </Flexbox>

                    <Flexbox
                        flexDirection='row'
                        justifyContent='space-between'
                        style={{ marginTop: '15px' }}
                    >
                        <MessageLikeButton
                            liked={props.message.liked}
                            num_likes={props.message.num_likes}
                            post_id={props.message.post_id}
                            userLikePost={userLikePost}
                            userUnlikePost={userUnlikePost}
                        />
                        <div></div>
                        {props.message.can_delete ? (
                            <MessageDeleteButton
                                post_id={props.message.post_id}
                            />
                        ) : (
                            <div />
                        )}
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        </div>
    )
}
