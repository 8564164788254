import * as types from './types'

// Get all API URL endpoints
export function validate(validateEndpoint, host) {
  return (dispatch, getState) => {
    if (validateEndpoint === undefined || validateEndpoint === null) {
      // eslint-disable-next-line no-console
      console.error(
        "You must pass 'validateEndpoint' as a parameter to 'WebCore"
      )
      return
    }

    if (host === undefined || host === null) {
      // eslint-disable-next-line no-console
      console.error("You must pass 'host' as a parameter to 'WebCore")
      return
    }

    dispatch(setHOST(host))
    const url = host + validateEndpoint

    const reqq = new XMLHttpRequest()
    reqq.onreadystatechange = (e) => {
      try {
        if (reqq.readyState !== 4) {
          return
        }

        if (reqq.status === 200) {
          if (reqq.responseText != null) {
            const x = JSON.parse(reqq.responseText)

            if (x.error) {
              dispatch(setEndpointURLS(null))
            } else {
              if (x.data) {
                const info = x.data
                dispatch(setEndpointURLS(info))
              }
            }
          }
        } else {
          dispatch(setEndpointURLS(null))
        }
      } catch (error) {
        //
        //  MyBugSnag.getInstance().client.notify(error);
        dispatch(setEndpointURLS(null))
      }
    }
    reqq.open('POST', url)
    reqq.send()
  }
}

function setEndpointURLS(info) {
  return {
    type: types.SET_ENDPOINT_URLS,
    info
  }
}

function setHOST(host) {
  return {
    type: types.SET_HOST,
    host
  }
}

export function setBasename(basename) {
  if (basename === undefined || basename === null) {
    // eslint-disable-next-line no-console
    console.error("You must pass 'basename' as a parameter to 'WebCore")
    return
  }

  return {
    type: types.SET_BASENAME,
    basename
  }
}

export function setAppName(appName) {
  if (appName === undefined || appName === null) {
    // eslint-disable-next-line no-console
    console.error("You must pass 'appName' as a parameter to 'WebCore")
    return
  }

  return {
    type: types.SET_APPNAME,
    appName
  }
}

export function setTheme(theme) {
  if (theme === undefined || theme === null) {
    // eslint-disable-next-line no-console
    console.error("You must pass 'theme' as a parameter to 'WebCore")
    return
  }

  return {
    type: types.SET_THEME,
    theme
  }
}

export function setAuthGroup(authGroup) {
  if (authGroup === undefined || authGroup === null) {
    // eslint-disable-next-line no-console
    console.error("You must pass 'authGroup' as a parameter to 'WebCore")
    return
  }

  return {
    type: types.SET_AUTH_GROUP,
    authGroup
  }
}

export function setDebug(debug) {
  if (debug === undefined || debug === null) {
    // eslint-disable-next-line no-console
    console.error("You must pass 'debug' as a parameter to 'WebCore")
    return
  }

  return {
    type: types.SET_DEBUG,
    debug
  }
}
