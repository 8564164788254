import React, { useEffect } from 'react'
import Tweet from './Tweet'
import InfiniteScroll from 'react-infinite-scroller'
import { Loader } from 'semantic-ui-react'

import { NUM_STREAM_FETCH } from '../Constants';

export default function StreamView({
    messages = {
        last_loaded_message_id: 0,
        has_more_messages: false,
        list: []
    },
    getMessages = () => {},
    streamType = 0,
}) {
    useEffect(() => {
        const refreshStreamInterval = setInterval(
            () => {
                if (streamType === 1) {
                    getMessages(false, messages.last_loaded_message_id);
                } else {
                    getMessages(messages.last_loaded_message_id, NUM_STREAM_FETCH);
                }
            },
            300000
        )
        return () => clearInterval(refreshStreamInterval)
    }, [])

    const loadMoreMessages = () => {
        if (streamType === 1) {
            getMessages(true, messages.last_loaded_message_id)
        } else {
            getMessages(messages.last_loaded_message_id, NUM_STREAM_FETCH);
        }
    }

    return (
        <InfiniteScroll
            pageStart={0}
            loadMore={loadMoreMessages}
            hasMore={messages.has_more_messages}
            loader={
                <div className='loader' key={0}>
                    <Loader style={{ position: 'relative' }} active />
                </div>
            }
        >
            {messages.list.map((message, index) => (
                <Tweet key={index} message={message} />
            ))}
        </InfiniteScroll>
    )
}
