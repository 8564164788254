import {
    executeBasicNetworkCall,
    getURLForEndpointName,
    HOST,
    ENDPOINTS,
    futch
} from '@linxsystems/web-core'
import * as types from './types'

export function getMessages(
    request_old = false,
    last_id = 0,
    callback = () => {}
) {
    return (dispatch, getState) => {
        let params = { request_old, last_id }
        params = JSON.stringify(params)

        executeBasicNetworkCall(
            'get_messages',
            params,
            'application/json',
            'POST',
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText)
                    if (x.error) {
                        callback()
                    } else {
                        callback()
                        dispatch(setMessages(x.data, request_old))
                    }
                }
            }
        )
    }
}

function setMessages(data, request_old) {
    return {
        type: types.SET_MESSAGES,
        data,
        request_old
    }
}

export function getObservationStateStream(last_post_id = null, num_requested = 0, callback = () => {}) {
    return (dispatch, getState) => {
        getStateStream(getState, "observation", last_post_id, num_requested, (success, data) => {
            if (success) {
                let convertedData = [];
                data.map((observation) => {
                    return convertedData.push(convertV3ObservationToV1(observation));
                })
                dispatch(setStateStreamObservations(convertedData));
                // callback();
            } else {
                callback();
            }
        });
    };
}

function setStateStreamObservations(stateStream) {
    return {
        type: types.SET_STATE_STREAM,
        stateStream,
    };
}

function convertV3ObservationToV1(observation) {
    let convertedObservation = {
        can_delete: false,
        chirp_id: "",
        created_by_user_id: "",
        created_by_user_name: "",
        created_by_user_profile_pic: "",
        date_created: "",
        id: "",
        liked: false,
        media_large_url: "",
        media_medium_url: "",
        media_small_url: "",
        media_tiny_url: "",
        media_url: "",
        message: "",
        num_likes: 0,
        post_id: "",
    };
    convertedObservation.created_by_user_name = observation.author;
    convertedObservation.created_by_user_profile_pic = observation.author_profile_pic;
    convertedObservation.date_created = observation.date;
    convertedObservation.media_url = observation.image;
    convertedObservation.liked = observation.isLiked;
    convertedObservation.num_likes = observation.numLikes;
    convertedObservation.chirp_id = observation.observation_id;
    convertedObservation.id = observation.post_id;
    convertedObservation.post_id = observation.post_id;
    convertedObservation.message = observation.text;

    return convertedObservation;
}

export function getStateStream(getState, media_type, last_post_id = null, num_requested = 0, callback = () => {}) {
    const params = { media_type, num_requested };

    if (last_post_id) {
        params.last_post_id = last_post_id;
    }

    const overrideURL = `${HOST()}/API/v3/GetStateStream`

    executeBasicNetworkCall(
        "get_state_stream",
        JSON.stringify(params),
        "application/json",
        "POST",
        getState,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    console.log("resp error", x.message);
                    callback(false);
                } else {
                    const data = x.data.stream_data;
                    callback(true, data);
                }
            }
        },
        (err) => {
            console.log("error:", err);
            callback(false);
        },
        false,
        (status) => {
            console.log("bad status:", status);
            callback(false);
        },
        overrideURL,  // overrideURL to call from v3
    );
}

export function createInnercomMessage(
    message,
    image,
    video,
    callback = () => {}
) {
    return (dispatch, getState) => {
        const endpoints = ENDPOINTS()

        if (endpoints === null) {
            return
        }

        // BUILD THE API URL
        const API_VERSION = endpoints.current_api_version
        //const ENDPOINT = getURLForEndpointName("create_innercom_message", endpoints.urls);
        const ENDPOINT = getURLForEndpointName('create_message', endpoints.urls)
        const URL = `${HOST()}/API/${API_VERSION}${ENDPOINT}`

        const formData = new FormData()

        formData.append('message', message)

        if (image) {
            formData.append('images', image)
        }
        if (video) {
            formData.append('videos', video)
        }

        futch(
            URL,
            {
                method: 'POST',
                body: formData,
                headers: {
                    // 'Content-Type': "multipart/form-data; boundary=Boundary+C95830F6ED24D5D1"
                }
            },
            (progressEvent) => {
                const progress = progressEvent.loaded / progressEvent.total
                // eslint-disable-next-line no-console
                console.log('progress', progress)
            }
        ).then(
            (response) => {
                // eslint-disable-next-line no-console
                console.log('res', response)

                callback(true)
                // eslint-disable-next-line no-console
            },
            (err) => console.warn(err)
        )
    }
}

export function createMessage(
    message = '',
    callback = () => {},
    failure = () => {}
) {
    return (dispatch, getState) => {
        let params = { message }
        params = JSON.stringify(params)

        executeBasicNetworkCall(
            'create_message',
            params,
            'application/json',
            'POST',
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText)
                    if (x.error) {
                        failure(x.message)
                    } else {
                        callback()
                    }
                }
            }
        )
    }
}

export function deleteMessage(
    message_id,
    callback = () => {},
    failure = () => {}
) {
    return (dispatch, getState) => {
        let params = { message_id }
        params = JSON.stringify(params)

        executeBasicNetworkCall(
            'delete_message',
            params,
            'application/json',
            'POST',
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText)
                    if (x.error) {
                        failure(x.message)
                    } else {
                        callback()
                    }
                }
            }
        )
    }
}

export function userLikeMessage(
    message_id,
    callback = () => {},
    failure = () => {}
) {
    return (dispatch, getState) => {
        let params = { message_id }
        params = JSON.stringify(params)

        executeBasicNetworkCall(
            'user_like_message',
            params,
            'application/json',
            'POST',
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText)
                    if (x.error) {
                        failure(x.message)
                    } else {
                        callback()
                    }
                }
            }
        )
    }
}

export function userUnlikeMessage(
    message_id,
    callback = () => {},
    failure = () => {}
) {
    return (dispatch, getState) => {
        let params = { message_id }
        params = JSON.stringify(params)

        executeBasicNetworkCall(
            'user_unlike_message',
            params,
            'application/json',
            'POST',
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText)
                    if (x.error) {
                        failure(x.message)
                    } else {
                        callback()
                    }
                }
            }
        )
    }
}

export function userViewMessage(
    message_id,
    callback = () => {},
    failure = () => {}
) {
    return (dispatch, getState) => {
        let params = { message_id }
        params = JSON.stringify(params)

        executeBasicNetworkCall(
            'user_view_message',
            params,
            'application/json',
            'POST',
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText)
                    if (x.error) {
                        failure(x.message)
                    } else {
                        callback()
                    }
                }
            }
        )
    }
}

// CHIRPS WITH HASHTAG ANALYTICS
export function createChirpV2(
    message = '',
    image,
    video,
    states = [],
    br_districts = [],
    br_regions = [],
    crops = [],
    products = [],
    is_customer_exclusive,
    callback = () => {}
) {
    return (dispatch, getState) => {
        const endpoints = ENDPOINTS()

        if (endpoints === null) {
            return
        }

        // BUILD THE API URL
        const API_VERSION = endpoints.current_api_version
        const ENDPOINT = getURLForEndpointName('create_chirp', endpoints.urls)
        const URL = `${HOST()}/API/${API_VERSION}${ENDPOINT}`

        const formData = new FormData()

        formData.append('message', message)
        formData.append('states', JSON.stringify(states))
        formData.append('br_districts', JSON.stringify(br_districts))
        formData.append('br_regions', JSON.stringify(br_regions))
        formData.append('crops', JSON.stringify(crops))
        formData.append('products', JSON.stringify(products))
        formData.append('is_customer_exclusive', is_customer_exclusive)

        if (image) {
            formData.append('images', image)
        }
        if (video) {
            formData.append('videos', video)
        }

        futch(
            URL,
            {
                method: 'POST',
                body: formData,
                headers: {
                    // 'Content-Type': "multipart/form-data; boundary=Boundary+C95830F6ED24D5D1"
                }
            },
            (progressEvent) => {
                const progress = progressEvent.loaded / progressEvent.total
                // eslint-disable-next-line no-console
                console.log('progress', progress)
            }
        ).then(
            (response) => {
                // eslint-disable-next-line no-console
                console.log('res', response)

                callback(true)
                // eslint-disable-next-line no-console
            },
            (err) => console.warn(err)
        )
    }
}

// CHIRPS WITH FOCUS CROP/PRODUCT/INSIGHT
export function createChirpWithFocusTag(
    message = '',
    image,
    video,
    states = [],
    br_districts = [],
    br_regions = [],
    content_category_id,
    focus_crop_id,
    focus_product_id,
    is_customer_exclusive,
    callback = () => {}
) {
    return (dispatch, getState) => {
        const endpoints = ENDPOINTS()

        if (endpoints === null) {
            return
        }

        // BUILD THE API URL
        const API_VERSION = endpoints.current_api_version
        const ENDPOINT = getURLForEndpointName('create_chirp_v3', endpoints.urls)
        const URL = `${HOST()}/API/${API_VERSION}${ENDPOINT}`

        const formData = new FormData()

        formData.append('message', message)
        formData.append('states', JSON.stringify(states))
        formData.append('br_districts', JSON.stringify(br_districts))
        formData.append('br_regions', JSON.stringify(br_regions))
        formData.append('content_category_id', JSON.stringify(content_category_id))
        formData.append('focus_crop_id', JSON.stringify(focus_crop_id))
        formData.append('focus_product_id', JSON.stringify(focus_product_id))
        formData.append('is_customer_exclusive', is_customer_exclusive)

        if (image) {
            formData.append('images', image)
        }
        if (video) {
            formData.append('videos', video)
        }

        futch(
            URL,
            {
                method: 'POST',
                body: formData,
                headers: {
                    // 'Content-Type': "multipart/form-data; boundary=Boundary+C95830F6ED24D5D1"
                }
            },
            (progressEvent) => {
                const progress = progressEvent.loaded / progressEvent.total
                // eslint-disable-next-line no-console
                console.log('progress', progress)
            }
        ).then(
            (response) => {
                // eslint-disable-next-line no-console
                console.log('res', response)

                callback(true)
                // eslint-disable-next-line no-console
            },
            (err) => console.warn(err)
        )
    }
}

export function createObservationNoPostV4(
    message = '',
    image,
    video,
    callback = () => {}
) {
    return (dispatch, getState) => {
        const endpoints = ENDPOINTS()

        if (endpoints === null) {
            return
        }

        // BUILD THE API URL
        const API_VERSION = endpoints.current_api_version
        const ENDPOINT = getURLForEndpointName('create_observation_no_post_v4', endpoints.urls)
        const URL = `${HOST()}/API/${API_VERSION}${ENDPOINT}`

        const formData = new FormData()

        formData.append('message', message)

        if (image) {
            formData.append('images', image)
        }
        if (video) {
            formData.append('videos', video)
        }

        futch(
            URL,
            {
                method: 'POST',
                body: formData,
                headers: {
                    // 'Content-Type': "multipart/form-data; boundary=Boundary+C95830F6ED24D5D1"
                }
            },
            (progressEvent) => {
                const progress = progressEvent.loaded / progressEvent.total
                // eslint-disable-next-line no-console
                console.log('progress', progress)
            }
        ).then(
            (response) => {
                // eslint-disable-next-line no-console
                console.log('res', response)

                callback(true)
                // eslint-disable-next-line no-console
            },
            (err) => console.warn(err)
        )
    }
}
