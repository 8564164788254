export const Generic = {
  logo: null,
  icon: null,

  LightestPrimaryColor: '#95b4de',
  LighterPrimaryColor: '#789fd4',
  LightPrimaryColor: '#5a8acb',
  PrimaryColor: '#4b7fc6',
  DarkPrimaryColor: '#3d75c0',
  DarkerPrimaryColor: '#3462a3',
  DarkestPrimaryColor: '#2a5085',

  LightestSecondaryColor: '#797b83',
  LighterSecondaryColor: '#666871',
  LightSecondaryColor: '#53555f',
  SecondaryColor: '#40434E',
  DarkSecondaryColor: '#393c46',
  DarkerSecondaryColor: '#33353e',
  DarkestSecondaryColor: '#2c2e36'
}
