import React, { useEffect, useState } from 'react'
import { Icon } from 'semantic-ui-react'
import Ticker from 'react-flip-ticker'
import Confetti from 'react-dom-confetti'
import Flexbox from 'flexbox-react'
import styles from './styles/MessageLikeButton.css'

export default function MessageLikeButton({
    liked = false,
    post_id = 1,
    num_likes = 0 + '',
    userLikePost = () => {},
    userUnlikePost = () => {}
}) {
    const [isLiked, setIsLiked] = useState(liked)
    const [numLikes, setNumLikes] = useState(num_likes + '')
    const [fireConfetti, setFireConfetti] = useState(false)
    const [doNotAnimate, setDoNotAnimate] = useState(false)

    useEffect(() => {
        setIsLiked(liked)
        setNumLikes(num_likes + '')
        setDoNotAnimate(true)

        const timer = setTimeout(() => {
            setDoNotAnimate(false)
        }, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [post_id])

    const toggleLike = () => {
        let num_likes = parseInt(numLikes)
        if (isLiked) {
            num_likes = num_likes - 1
            //api for unlike message
            userUnlikePost(post_id)
        } else {
            num_likes = num_likes + 1
            setFireConfetti(true)
            //api for like message
            userLikePost(post_id)
        }

        setTimeout(() => {
            setNumLikes(num_likes + '')
            setIsLiked(!isLiked)
            setFireConfetti(false)
        }, 10)
    }

    const config = {
        angle: 90,
        spread: 45,
        startVelocity: 9,
        elementCount: 20,
        dragFriction: 0.1,
        duration: 1000,
        stagger: 0,
        width: '5px',
        height: '5px',
        colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
    }

    return (
        <Flexbox
            flexDirection='row'
            onClick={toggleLike}
            className={styles.like_button}
            style={{ color: isLiked ? '#e0245e' : '' }}
        >
            {isLiked ? <Icon name='heart' /> : <Icon name='heart outline' />}
            <Confetti active={fireConfetti} config={config} />
            {doNotAnimate ? (
                numLikes
            ) : (
                <Ticker text={numLikes} textClassName='number' />
            )}
        </Flexbox>
    )
}
