import { Generic } from './Generic'
import { GrowSmartLive } from './GrowSmartLive'
import { AgMarket } from './AgMarket'
import { CirrusLife } from './CirrusLife'
import { Wilson } from './Wilson'
import { RRMensVIP } from './RRMensVIP'
import { store } from '../redux/store'

const getDefaultTheme = (theme = null) => {
  if (theme === null) {
    return store.getState().core.theme
  } else {
    return theme
  }
}

export const getLogo = (themeName = null) => {
  const theme = getDefaultTheme(themeName)
  switch (theme) {
    case 'GrowSmartLive':
      return GrowSmartLive.logo
    case 'AgMarket':
      return AgMarket.logo
    case 'CirrusLife':
      return CirrusLife.logo
    case 'Wilson':
      return Wilson.logo
    case 'RRMensVIP':
      return RRMensVIP.logo
    default:
      return Generic.logo
  }
}

export const getIcon = (themeName = null) => {
  const theme = getDefaultTheme(themeName)
  switch (theme) {
    case 'GrowSmartLive':
      return GrowSmartLive.icon
    case 'AgMarket':
      return AgMarket.icon
    case 'CirrusLife':
      return CirrusLife.icon
    case 'Wilson':
      return Wilson.icon
    case 'RRMensVIP':
      return RRMensVIP.icon
    default:
      return Generic.icon
  }
}

export const getPrimaryThemeColor = (themeName = null) => {
  const theme = getDefaultTheme(themeName)
  switch (theme) {
    case 'GrowSmartLive':
      return GrowSmartLive.PrimaryColor
    case 'AgMarket':
      return AgMarket.PrimaryColor
    case 'CirrusLife':
      return CirrusLife.PrimaryColor
    case 'Wilson':
      return Wilson.PrimaryColor
    case 'RRMensVIP':
      return RRMensVIP.PrimaryColor
    default:
      return Generic.PrimaryColor
  }
}

export const getPrimaryLightThemeColor = (themeName = null) => {
  const theme = getDefaultTheme(themeName)
  switch (theme) {
    case 'GrowSmartLive':
      return GrowSmartLive.LightPrimaryColor
    case 'AgMarket':
      return AgMarket.LightPrimaryColor
    case 'CirrusLife':
      return CirrusLife.LightPrimaryColor
    case 'Wilson':
      return Wilson.LightPrimaryColor
    case 'RRMensVIP':
      return RRMensVIP.LightPrimaryColor
    default:
      return Generic.LightPrimaryColor
  }
}

export const getPrimaryLighterThemeColor = (themeName = null) => {
  const theme = getDefaultTheme(themeName)
  switch (theme) {
    case 'GrowSmartLive':
      return GrowSmartLive.LighterPrimaryColor
    case 'AgMarket':
      return AgMarket.LighterPrimaryColor
    case 'CirrusLife':
      return CirrusLife.LighterPrimaryColor
    case 'Wilson':
      return Wilson.LighterPrimaryColor
    case 'RRMensVIP':
      return RRMensVIP.LighterPrimaryColor
    default:
      return Generic.LighterPrimaryColor
  }
}

export const getPrimaryLightestThemeColor = (themeName = null) => {
  const theme = getDefaultTheme(themeName)
  switch (theme) {
    case 'GrowSmartLive':
      return GrowSmartLive.LightestPrimaryColor
    case 'AgMarket':
      return AgMarket.LightestPrimaryColor
    case 'CirrusLife':
      return CirrusLife.LightestPrimaryColor
    case 'Wilson':
      return Wilson.LightestPrimaryColor
    case 'RRMensVIP':
      return RRMensVIP.LightestPrimaryColor
    default:
      return Generic.LightestPrimaryColor
  }
}

export const getSecondaryThemeColor = (themeName = null) => {
  const theme = getDefaultTheme(themeName)
  switch (theme) {
    case 'GrowSmartLive':
      return GrowSmartLive.SecondaryColor
    case 'AgMarket':
      return AgMarket.SecondaryColor
    case 'CirrusLife':
      return CirrusLife.SecondaryColor
    case 'Wilson':
      return Wilson.SecondaryColor
    case 'RRMensVIP':
      return RRMensVIP.SecondaryColor
    default:
      return Generic.SecondaryColor
  }
}

export const getSecondaryLightThemeColor = (themeName = null) => {
  const theme = getDefaultTheme(themeName)
  switch (theme) {
    case 'GrowSmartLive':
      return GrowSmartLive.LightSecondaryColor
    case 'AgMarket':
      return AgMarket.LightSecondaryColor
    case 'CirrusLife':
      return CirrusLife.LightSecondaryColor
    case 'Wilson':
      return Wilson.LightSecondaryColor
    case 'RRMensVIP':
      return RRMensVIP.LightSecondaryColor
    default:
      return Generic.LightSecondaryColor
  }
}

export const LightText = '#FFFFFF' // white
export const GrayText = '#555' //gray
export const DarkText = '#070707' //black
