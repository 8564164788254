import React, { useEffect, useState } from 'react'
import Flexbox from 'flexbox-react'
import { Button, Dimmer, Loader, Modal, Radio } from 'semantic-ui-react'

import CropSvg from '../CropSvg';
import MultiSelectDropdown from '../MultiSelectDropdown'
import DropdownWithCategories from '../DropdownWithCategories'
import classnames from './TaggingModal.css';

export default function TaggingModal({
    modalOpen = false,
    closeModal = () => { },
    publish = () => { },
    publishCallback = () => { },
    getComposeDropdownList = () => { },
    token = null,
    user = undefined,
    prefillAudience = undefined,
    prefillStates = [],
    prefillDistricts = [],
    prefillRegions = [],
    prefillCrops = [],
    prefillProducts = [],
    prefillContentType = undefined,
    prefillFocusCrop = undefined,
    prefillFocusProduct = undefined,
}) {
    const [stateOptions, setStateOptions] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [cropOptions, setCropOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [contentCategoryOptions, setContentCategoryOptions] = useState([]);

    const [stateDropdownOpen, setStateDropdownOpen] = useState(false);
    const [districtDropdownOpen, setDistrictDropdownOpen] = useState(false);
    const [regionDropdownOpen, setRegionDropdownOpen] = useState(false);
    const [cropDropdownOpen, setCropDropdownOpen] = useState(false);
    const [productDropdownOpen, setProductDropdownOpen] = useState(false);

    const [isCustomerExclusive, setIsCustomerExclusive] = useState(undefined);
    const [selectedStates, setSelectedStates] = useState([]);
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    // const [selectedCrops, setSelectedCrops] = useState([]);
    // const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedContentType, setSelectedContentType] = useState(null);
    const [selectedFocusCrop, setSelectedFocusCrop] = useState(null);
    const [selectedFocusProduct, setSelectedFocusProduct] = useState(null);

    const [audienceError, setAudienceError] = useState(false);
    const [statesError, setStatesError] = useState(false);
    const [districtsError, setDistrictsError] = useState(false);
    const [regionsError, setRegionsError] = useState(false);
    const [cropsError, setCropsError] = useState(false);
    const [productsError, setProductsError] = useState(false);
    const [contentTypeError, setContentTypeError] = useState(false);

    const [publishing, setPublishing] = useState(false);

    const [modalPage, setModalPage] = useState(1);

    useEffect(
        () => {
            setIsCustomerExclusive(prefillAudience);
            setSelectedDistricts(prefillDistricts.map(({ name }) => name));
            setSelectedRegions(prefillRegions.map(({ name }) => name));
            setSelectedContentType(prefillContentType);
            setSelectedFocusCrop(prefillFocusCrop);
            setSelectedFocusProduct(prefillFocusProduct);

            getComposeDropdownList((success, data) => {
                if (success) {
                    setStateOptions(data.states);
                    setDistrictOptions(data.br_districts);
                    setRegionOptions(data.regions);
                    setCropOptions(data.crops);
                    setProductOptions(data.products);
                    setContentCategoryOptions(data.content_categories);

                    // match prefillStates to choices from the stateOptions list
                    // this is to correct an issue that arose from showing tags in v2 streams
                    if (prefillStates.length > 0) {
                        let shortNamesList = []
                        prefillStates.map((prefillVal) => {
                            return data.states.map((option) => {
                                if (option.key === prefillVal.id) { return shortNamesList.push(option.value) } else return null;
                            })
                        })
                        setSelectedStates(shortNamesList);
                    }

                }
            }, token);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const successCallback = () => {
        setPublishing(false)
        setIsCustomerExclusive(undefined)
        setSelectedStates([])
        setSelectedDistricts([])
        setSelectedRegions([])
        // setSelectedProducts([])
        // setSelectedCrops([])
        setSelectedContentType(undefined);
        setSelectedFocusCrop(undefined);
        setSelectedFocusProduct(undefined);
        onClose(); // closeModal()
        publishCallback(true)
    }

    const validatePage1 = () => {
        let errors = 0;

        if (isCustomerExclusive === undefined || isCustomerExclusive === null) {
            errors++;
            setAudienceError(true);
        }

        if (selectedStates.length === 0 && selectedDistricts.length === 0 && selectedRegions.length === 0) {
            errors++;
            setStatesError(true);
            setDistrictsError(true);
            setRegionsError(true);
        }

        if (errors === 0) {
            setModalPage(2);
        }
    }

    const publishWithHashtag = () => {
        let errors = 0;

        if (isCustomerExclusive === undefined || isCustomerExclusive === null) {
            errors++;
            setAudienceError(true);
            setModalPage(1);
        }

        if (selectedStates.length === 0 && selectedDistricts.length === 0 && selectedRegions.length === 0) {
            errors++;
            setStatesError(true);
            setDistrictsError(true);
            setRegionsError(true);
            setModalPage(1);
        }

        // if (selectedCrops.length === 0) {
        //     errors++;
        //     setCropsError(true);
        // }

        // if (selectedProducts.length === 0) {
        //     errors++;
        //     setProductsError(true);
        // }

        // let is_customer_exclusive = true;
        // if (selectedRegions.length > 0) {
        //     is_customer_exclusive = false;
        // }

        const states = getKeyArray(selectedStates, stateOptions);
        const districts = getKeyArray(selectedDistricts, districtOptions);
        const regions = getKeyArray(selectedRegions, regionOptions);
        // const crops = getKeyArray(selectedCrops, cropOptions);
        // const products = getKeyArray(selectedProducts, productOptions);

        if (!selectedContentType) {
            errors++;
            setContentTypeError(true);
        }

        let focusCropKey = undefined;
        let focusProductKey = undefined;
        if (selectedContentType === 1) {
            // check that we have a crop value and find the key
            if (!selectedFocusCrop) {
                errors++;
                setCropsError(true);
            } else {
                focusCropKey = getKey(selectedFocusCrop, cropOptions);
                if (!focusCropKey) {
                    errors++;
                    setCropsError(true);
                }
            }

            // check that we have a product value and find the key
            if (!selectedFocusProduct) {
                errors++;
                setProductsError(true);
            } else {
                focusProductKey = getKey(selectedFocusProduct, productOptions);
                if (!focusProductKey) {
                    errors++;
                    setProductsError(true);
                }
            }

        }

        if (errors === 0) {
            setPublishing(true);
            publish(false, states, districts, regions, selectedContentType, focusCropKey, focusProductKey, isCustomerExclusive, successCallback);
        }
    };

    // get array of keys/IDs of the selected options
    const getKeyArray = (selectedValues, options) => {
        let allOptions = options;
        if (options.length > 0 && options[0].header) {
            allOptions = [];
            options.forEach((item) => {
                allOptions = allOptions.concat(item.data);
            });
        }

        let keyArray = selectedValues.map(
            (val) => allOptions.find((el) => el.value === val).key,
        );

        return keyArray;
    };
    // get single key/ID of the selected option
    const getKey = (selectedValue, options) => {
        let allOptions = options;
        if (options.length > 0 && options[0].header) {
            allOptions = [];
            options.forEach((item) => {
                allOptions = allOptions.concat(item.data);
            });
        }

        return allOptions.find((el) => el.value === selectedValue)?.key
    };

    const hashtagStyles = {
        cropSvg: { position: "absolute", top: 7, left: 9 },
        leftIcon: { color: "#80af47", opacity: 1 },
        leftIconDisabled: { color: "#a5a5a5", opacity: .8 },
        dropdownStyle: { paddingLeft: 30, minWidth: 170 },
    };

    const onClose = () => {
        setAudienceError(false);
        setStatesError(false);
        setDistrictsError(false);
        setRegionsError(false);
        setContentTypeError(false);
        setCropsError(false);
        setProductsError(false);
        setModalPage(1);
        closeModal();
    };

    const changeContentType = (contentTypeID = undefined) => {
        setSelectedContentType(contentTypeID);
        setContentTypeError(false);
        if (contentTypeID !== 1) {
            setCropsError(false);
            setProductsError(false);
        }
    }

    const resetLocationSelections = () => {
        setSelectedStates([]);
        setSelectedDistricts([]);
        setSelectedRegions([]);
        setStatesError(false);
        setDistrictsError(false);
        setRegionsError(false);
    };

    if (publishing) {
        return <Dimmer active children={<Loader />} />;
    }

    const renderPage1Audience = () => {
        const isAcctMng = (user?.groupID === 1);
        return (<React.Fragment>
            <div>Audience</div>
            <p className={classnames.tagging_modal__subheader}>
                What kind of users will see your content?
            </p>
            <Flexbox flexDirection="row" flexWrap="wrap">
                <Flexbox
                    flexDirection="row"
                    // Freemium is ONLY an option for AcctMng in current GSL iteration
                    style={{ margin: '10px 20px 10px 5px', cursor: isAcctMng ? 'pointer' : 'not-allowed' }}
                    title={isAcctMng ? '' : 'Coming Soon!'}
                    onClick={() => {
                        if (isAcctMng) {
                            setIsCustomerExclusive(false); setAudienceError(false);
                            resetLocationSelections(); setSelectedRegions(regionOptions.map((opt, i) => { return opt.value; }));
                        }
                    }}
                >
                    <Radio
                        disabled={!isAcctMng}
                        name='audienceSelector'
                        checked={isCustomerExclusive === false}
                    />
                    <Flexbox flexDirection="column" style={{ marginLeft: 5, opacity: isAcctMng ? 1 : 0.6 }}>
                        <div style={{ fontWeight: 'bold' }}>Freemium + Premium Users</div>
                        <p className={classnames.tagging_modal__subheader}>Viewable by all users, nationwide</p>
                    </Flexbox>
                </Flexbox>
                <Flexbox
                    flexDirection="row"
                    style={{ margin: '10px 20px 10px 5px', cursor: 'pointer' }}
                    onClick={() => { setIsCustomerExclusive(true); setAudienceError(false); resetLocationSelections(); }}
                >
                    <Radio
                        name='audienceSelector'
                        checked={isCustomerExclusive}
                    />
                    <Flexbox flexDirection="column" style={{ marginLeft: 5 }}>
                        <div style={{ fontWeight: 'bold' }}>Only Premium Users</div>
                        <p className={classnames.tagging_modal__subheader}>Viewable only by logged-in users within the location</p>
                    </Flexbox>
                </Flexbox>
            </Flexbox>
            {audienceError ?
                <div className={classnames.tagging_modal__audience_error}>
                    Please select an audience.
                </div>
                : null
            }
            <div className={classnames.tagging_modal__divider} />
            <div>Location</div>
            <p className={classnames.tagging_modal__subheader}>
                What geographical areas can view this? Make selections from only one category: States, BR districts, or Region.
            </p>
            <Flexbox flexDirection="row" flexWrap="wrap">
                <Flexbox flexDirection="column">
                    <Flexbox flexDirection='row' justifyContent='space-between' alignItems='flex-end'
                        style={{ paddingRight: '10px' }}>
                        <p className={!isCustomerExclusive || selectedDistricts.length > 0 || selectedRegions.length > 0 ? classnames.tagging_modal__label__disabled : classnames.tagging_modal__label}>State</p>
                        {stateDropdownOpen && <p className={classnames.tagging_modal__done_button}>Done</p>}
                    </Flexbox>
                    <div
                        className='ui left icon input'
                        style={{ margin: 10, marginTop: 0 }}
                    >
                        <MultiSelectDropdown
                            name="States"
                            placeholder="Select State"
                            options={stateOptions}
                            setValues={setSelectedStates}
                            values={selectedStates}
                            style={hashtagStyles.dropdownStyle}
                            error={statesError}
                            onFocus={() => setStatesError(false)}
                            onOpen={() => setStateDropdownOpen(true)}
                            onClose={() => setStateDropdownOpen(false)}
                            disabled={!isCustomerExclusive || selectedDistricts.length > 0 || selectedRegions.length > 0}
                            clearable={isCustomerExclusive}
                        />
                        <i
                            aria-hidden="true"
                            className="map marker alternate icon"
                            style={!isCustomerExclusive || selectedDistricts.length > 0 || selectedRegions.length > 0 ? hashtagStyles.leftIconDisabled : hashtagStyles.leftIcon}
                        />
                    </div>
                </Flexbox>
                <Flexbox flexDirection="column">
                    <Flexbox flexDirection='row' justifyContent='space-between' alignItems='flex-end'
                        style={{ paddingRight: '10px' }}>
                        <p className={!isCustomerExclusive || selectedStates.length > 0 || selectedRegions.length > 0 ? classnames.tagging_modal__label__disabled : classnames.tagging_modal__label}>BR District</p>
                        {districtDropdownOpen && <p className={classnames.tagging_modal__done_button}>Done</p>}
                    </Flexbox>
                    <div
                        className='ui left icon input'
                        style={{ margin: 10, marginTop: 0 }}
                    >
                        <MultiSelectDropdown
                            name="Districts"
                            placeholder="Select District"
                            options={districtOptions}
                            setValues={setSelectedDistricts}
                            values={selectedDistricts}
                            style={hashtagStyles.dropdownStyle}
                            error={districtsError}
                            onFocus={() => setDistrictsError(false)}
                            onOpen={() => setDistrictDropdownOpen(true)}
                            onClose={() => setDistrictDropdownOpen(false)}
                            disabled={!isCustomerExclusive || selectedStates.length > 0 || selectedRegions.length > 0}
                            clearable={isCustomerExclusive}
                        />
                        <i
                            aria-hidden="true"
                            className="map marker alternate icon"
                            style={!isCustomerExclusive || selectedStates.length > 0 || selectedRegions.length > 0 ? hashtagStyles.leftIconDisabled : hashtagStyles.leftIcon}
                        />
                    </div>
                </Flexbox>
                <Flexbox flexDirection="column">
                    <Flexbox flexDirection='row' justifyContent='space-between' alignItems='flex-end'
                        style={{ paddingRight: '10px' }}>
                        <p className={!isCustomerExclusive || selectedStates.length > 0 || selectedDistricts.length > 0 ? classnames.tagging_modal__label__disabled : classnames.tagging_modal__label}>Region</p>
                        {regionDropdownOpen && <p className={classnames.tagging_modal__done_button}>Done</p>}
                    </Flexbox>
                    <div
                        className='ui left icon input'
                        style={{ margin: 10, marginTop: 0 }}
                    >
                        <MultiSelectDropdown
                            name="Regions"
                            placeholder="Select Region"
                            options={regionOptions}
                            setValues={setSelectedRegions}
                            values={selectedRegions}
                            style={hashtagStyles.dropdownStyle}
                            error={regionsError}
                            onFocus={() => setRegionsError(false)}
                            onOpen={() => setRegionDropdownOpen(true)}
                            onClose={() => setRegionDropdownOpen(false)}
                            disabled={!isCustomerExclusive || selectedStates.length > 0 || selectedDistricts.length > 0}
                            clearable={isCustomerExclusive}
                        />
                        <i
                            aria-hidden="true"
                            className="map marker alternate icon"
                            style={!isCustomerExclusive || selectedStates.length > 0 || selectedDistricts.length > 0 ? hashtagStyles.leftIconDisabled : hashtagStyles.leftIcon}
                        />
                    </div>
                </Flexbox>
            </Flexbox>
            <div className={classnames.tagging_modal__divider} />
            <Flexbox justifyContent="center" alignItems="center">
                <Button
                    content="NEXT"
                    className={classnames.tagging_modal__next_button}
                    onClick={() => validatePage1()}
                />
            </Flexbox>
        </React.Fragment>);
    }

    const renderPage2Content = () => {
        return (<React.Fragment>
            <div>Content Type</div>
            <p className={classnames.tagging_modal__subheader}>
                Choose one type for this content: Focus Crop/Product, Market Insight, or Agronomic Insight
            </p>
            <Flexbox flexDirection='column'>
                {contentCategoryOptions.map((option) => {
                    return (<Flexbox
                        key={option.id}
                        flexDirection="row"
                        style={{ margin: '10px 20px 10px 5px' }}
                        flexWrap='wrap'
                    >
                        <Radio
                            name='contentType'
                            checked={selectedContentType === option.id}
                            onClick={() => changeContentType(option.id)}
                        />
                        <Flexbox flexDirection="column" style={{ marginLeft: 5, cursor: 'pointer' }} onClick={() => changeContentType(option.id)}>
                            <div style={{ fontWeight: 'bold' }}>{option.name}</div>
                            <p className={classnames.tagging_modal__description}>{option.description}</p>
                        </Flexbox>
                        {option.id === 1 ? <Flexbox flexDirection='row' flexWrap='wrap'>
                            <Flexbox flexDirection="column">
                                <Flexbox flexDirection='row' justifyContent='space-between' alignItems='flex-end'
                                    style={{ paddingRight: '10px' }}>
                                    <p className={classnames.tagging_modal__label}>Focus Crop</p>
                                    {cropDropdownOpen && <p className={classnames.tagging_modal__done_button}>Done</p>}
                                </Flexbox>
                                <div
                                    className='ui left icon input'
                                    style={{ margin: 10, marginTop: 0 }}
                                >
                                    <DropdownWithCategories
                                        name="Crops"
                                        placeholder="Select Crop"
                                        options={cropOptions}
                                        setValue={setSelectedFocusCrop}
                                        value={selectedFocusCrop}
                                        style={hashtagStyles.dropdownStyle}
                                        error={cropsError}
                                        onFocus={() => setCropsError(false)}
                                        onOpen={() => setCropDropdownOpen(true)}
                                        onClose={() => setCropDropdownOpen(false)}
                                        disabled={selectedContentType !== 1}
                                    />
                                    <div style={hashtagStyles.cropSvg}>
                                        <CropSvg color="#80af47" />
                                    </div>
                                </div>
                            </Flexbox>
                            <Flexbox flexDirection="column">
                                <Flexbox flexDirection='row' justifyContent='space-between' alignItems='flex-end'
                                    style={{ paddingRight: '10px' }}>
                                    <p className={classnames.tagging_modal__label}>Focus Product</p>
                                    {productDropdownOpen && <p className={classnames.tagging_modal__done_button}>Done</p>}
                                </Flexbox>
                                <div
                                    className='ui left icon input'
                                    style={{ margin: 10, marginTop: 0 }}
                                >
                                    <DropdownWithCategories
                                        name="Products"
                                        placeholder="Select Product"
                                        options={productOptions}
                                        setValue={setSelectedFocusProduct}
                                        value={selectedFocusProduct}
                                        style={hashtagStyles.dropdownStyle}
                                        error={productsError}
                                        onFocus={() => setProductsError(false)}
                                        onOpen={() => setProductDropdownOpen(true)}
                                        onClose={() => setProductDropdownOpen(false)}
                                        disabled={selectedContentType !== 1}
                                    />
                                    <i
                                        aria-hidden="true"
                                        className="tag icon"
                                        style={hashtagStyles.leftIcon}
                                    />
                                </div>
                            </Flexbox>
                        </Flexbox> : null}
                    </Flexbox>);
                })}
            </Flexbox>
            {contentTypeError ?
                <div className={classnames.tagging_modal__audience_error}>
                    Please select a category for this content.
                </div>
                : null
            }

            <div className={classnames.tagging_modal__divider} />
            <Flexbox justifyContent="center" alignItems="center">
                <Button
                    content="BACK"
                    className={classnames.tagging_modal__back_button}
                    onClick={() => setModalPage(1)}
                />
                <Button
                    content="PUBLISH"
                    className={classnames.tagging_modal__submit_button}
                    onClick={publishWithHashtag}
                />
            </Flexbox>
        </React.Fragment>);
    }

    return (
        <Modal
            open={modalOpen}
            onClose={onClose}
            closeOnDimmerClick={false}
            className={classnames.tagging_modal}
            closeIcon={
                <i
                    aria-hidden='true'
                    className={`far fa-times-circle ${classnames.tagging_modal__close_icon}`}
                />
            }
        >
            <Modal.Content>
                <Flexbox flexDirection="column" style={{ padding: 10 }}>
                    <p className={classnames.tagging_modal__header}>Categorize your content (Part {modalPage} of 2)</p>
                    <div className={classnames.tagging_modal__divider} />
                    {modalPage === 2 ? renderPage2Content() : renderPage1Audience()}
                    <Flexbox flexDirection='row' justifyContent='center' style={{ marginTop: 10 }}>
                        <div className={modalPage === 1 ? classnames.tagging_modal__circle_full : classnames.tagging_modal__circle_empty} />
                        <div className={modalPage === 2 ? classnames.tagging_modal__circle_full : classnames.tagging_modal__circle_empty} />
                    </Flexbox>
                </Flexbox>
            </Modal.Content>
        </Modal>
    );
}
