export const RRMensVIP = {
  LightestPrimaryColor: '#b2b2b2',
  LighterPrimaryColor: '#7f7f7f',
  LightPrimaryColor: '#323232',
  PrimaryColor: '#000000',
  DarkPrimaryColor: '#070707',
  DarkerPrimaryColor: '#070707',
  DarkestPrimaryColor: '#070707',

  LightestSecondaryColor: '#797b83',
  LighterSecondaryColor: '#666871',
  LightSecondaryColor: '#53555f',
  SecondaryColor: '#40434E',
  DarkSecondaryColor: '#393c46',
  DarkerSecondaryColor: '#33353e',
  DarkestSecondaryColor: '#2c2e36'
}
