import { store } from '../redux/store'

export function getURLForEndpointName(name, urls) {
  for (let i = 0; i < urls.length; i++) {
    const endpoint = urls[i]
    if (endpoint.name === name) {
      return endpoint.url
    }
  }
}

export function executeBasicNetworkCall(
  endpoint,
  params,
  contentType,
  httpMethod = 'POST',
  getState = null, // deprecated
  onReady = () => {},
  onError = () => {},
  debug = false,
  onBadStatus = () => {},
  overrideURL,
) {
  if (debug) {
    // eslint-disable-next-line no-console
    console.log(endpoint)
  }
  const endpoints = store.getState().core.endpoints

  if (endpoints === null && overrideURL === undefined) {
    return
  }
  if ((!endpoint || endpoint === null) && overrideURL === undefined) {
    return
  }

  const HOST = store.getState().core.host
  let URL = overrideURL;

  if (URL === undefined || URL === null) {
    // BUILD THE API URL
    const API_VERSION = endpoints.current_api_version
    const ENDPOINT = getURLForEndpointName(endpoint, endpoints.urls)
    URL = `${HOST}/API/${API_VERSION}${ENDPOINT}`
  }

  if (debug) {
    // eslint-disable-next-line no-console
    console.log(URL)
    // eslint-disable-next-line no-console
    console.log(endpoint)
  }

  const reqq = new XMLHttpRequest()
  reqq.onreadystatechange = (e) => {
    try {
      if (reqq.readyState !== 4) {
        return
      }
      if (debug) {
        // eslint-disable-next-line no-console
        console.log(reqq)
      }
      if (reqq.status !== 200) {
        onBadStatus(reqq.status)
        // if (Config.options.bugsnag) {
        //   Config.options.bugsnag.notify(
        //     new Error(`endpoint returned status ${reqq.status}`)
        //   )
        // }
      } else {
        onReady(reqq)
      }
    } catch (error) {
      // safari catches here for some reason.  Something related to PUT requests???
      if (error.message && error.message === 'Not enough arguments') {
        onReady(reqq)
        return
      }
      onError(error)
      // if (Config.options.bugsnag) {
      //   Config.options.bugsnag.notify(error)
      // }
    }
  }
  reqq.open(httpMethod, URL)
  reqq.setRequestHeader('Content-Type', contentType)
  reqq.withCredentials = true
  reqq.send(params)
}

export function validateEmailAddress(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function validateURL(url) {
  // eslint-disable-next-line no-useless-escape
  const re =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  return re.test(String(url).toLowerCase())
}

export function getAbbreviatedState(state) {
  const states = [
    ['Arizona', 'AZ'],
    ['Alabama', 'AL'],
    ['Alaska', 'AK'],
    ['Arkansas', 'AR'],
    ['California', 'CA'],
    ['Colorado', 'CO'],
    ['Connecticut', 'CT'],
    ['Delaware', 'DE'],
    ['Florida', 'FL'],
    ['Georgia', 'GA'],
    ['Hawaii', 'HI'],
    ['Idaho', 'ID'],
    ['Illinois', 'IL'],
    ['Indiana', 'IN'],
    ['Iowa', 'IA'],
    ['Kansas', 'KS'],
    ['Kentucky', 'KY'],
    ['Louisiana', 'LA'],
    ['Maine', 'ME'],
    ['Maryland', 'MD'],
    ['Massachusetts', 'MA'],
    ['Michigan', 'MI'],
    ['Minnesota', 'MN'],
    ['Mississippi', 'MS'],
    ['Missouri', 'MO'],
    ['Montana', 'MT'],
    ['Nebraska', 'NE'],
    ['Nevada', 'NV'],
    ['New Hampshire', 'NH'],
    ['New Jersey', 'NJ'],
    ['New Mexico', 'NM'],
    ['New York', 'NY'],
    ['North Carolina', 'NC'],
    ['North Dakota', 'ND'],
    ['Ohio', 'OH'],
    ['Oklahoma', 'OK'],
    ['Oregon', 'OR'],
    ['Pennsylvania', 'PA'],
    ['Rhode Island', 'RI'],
    ['South Carolina', 'SC'],
    ['South Dakota', 'SD'],
    ['Tennessee', 'TN'],
    ['Texas', 'TX'],
    ['Utah', 'UT'],
    ['Vermont', 'VT'],
    ['Virginia', 'VA'],
    ['Washington', 'WA'],
    ['West Virginia', 'WV'],
    ['Wisconsin', 'WI'],
    ['Wyoming', 'WY'],
    ['District of Columbia', 'DC'],
    ['Puerto Rico', 'PR']
  ]

  state = state.toLowerCase()
  for (let i = 0; i < states.length; i++) {
    if (states[i][0].toLowerCase() === state) {
      return states[i][1]
    }
  }
}

// immutable array splice function
export function splice(input, start, deleteCount) {
  deleteCount = deleteCount === null ? input.length - start : deleteCount
  const items = [].slice.call(arguments, 3)
  let output
  return (output = input.slice(0, start)).concat
    .apply(output, items)
    .concat(input.slice(start + deleteCount))
}
