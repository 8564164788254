import {
  ENDPOINTS,
  HOST,
  executeBasicNetworkCall,
  futch,
  getURLForEndpointName
} from '@linxsystems/web-core'
import * as MessageActions from './messages'
// import * as types from './types'

export function createOrUpdateProfilePicture(
  mr_id,
  azure_media_id,
  success = () => {},
  token,
  changeMyDetails = () => {}
) {
  return (dispatch, getState) => {
    let json = {}

    if (mr_id) {
      json = { mr_id }
    } else if (azure_media_id) {
      json = { azure_media_id }
    }

    if (token && token !== null) {
      json.auth_token = token
    }
    json = JSON.stringify(json)

    executeBasicNetworkCall(
      'create_or_update_profile_picture',
      json,
      'application/json',
      'POST',
      getState,
      (reqq) => {
        if (reqq.responseText != null) {
          const x = JSON.parse(reqq.responseText)
          if (x.error) {
            dispatch(MessageActions.setError(x.message))
          } else {
            success()
            changeMyDetails({ avatar_url: x.prof_pic })
            dispatch(MessageActions.setSuccess(x.message))
          }
        }
      }
    )
  }
}

export function uploadMediaToAzure(
  auth_token,
  image,
  video,
  successCallback = () => {},
  returnType = 'url',
  failureCallback = () => {},
  progressCallback = (progress) => {
    // eslint-disable-next-line no-console
    console.log('progress', progress)
  },
  tags = [],
  note = ''
) {
  return () => {
    const endpoints = ENDPOINTS()
    if (endpoints === null) {
      return
    }
    // BUILD THE API URL
    const API_VERSION = endpoints.current_api_version
    const ENDPOINT = getURLForEndpointName(
      'upload_media_to_azure',
      endpoints.urls
    )
    const URL = `${HOST()}/API/${API_VERSION}${ENDPOINT}`
    const formData = new FormData()
    formData.append('return_type', returnType)
    formData.append('auth_token', auth_token)
    formData.append('tags', tags)
    formData.append('note', note)

    let hasMedia = false
    if (image && image !== null) {
      hasMedia = true
      formData.append('images', image)
    }
    if (video && video !== null) {
      hasMedia = true
      formData.append('videos', video)
    }

    futch(
      URL,
      {
        method: 'POST',
        body: formData,
        headers: {
          // 'Content-Type': "multipart/form-data; boundary=Boundary+C95830F6ED24D5D1"
        }
      },
      (progressEvent) => {
        const progress = progressEvent.loaded / progressEvent.total
        progressCallback(progress)
      }
    ).then(
      (response) => {
        if (response.responseText) {
          const x = JSON.parse(response.responseText)
          if (x.error) {
            failureCallback(false)
          } else {
            if (x && x.data && x.data.length > 0 && hasMedia) {
              // we just uploaded an image.  Get the Azure URL and return that to the editor
              let resp = x.data[0]
              if (video !== null && returnType === 'id') {
                resp = x.data
              }
              successCallback(true, resp)
            }
          }
        }
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.warn(err)
        failureCallback(false)
      }
    )
  }
}
