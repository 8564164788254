import React, { useState } from 'react'
import Flexbox from 'flexbox-react'
import { Modal } from 'semantic-ui-react'
import styles from '../screens/TweeterHomeScreen/styles.css'

export default function TweetImage({
  media_url = '',
  media_medium_url = '',
  post_id = 1,
  userClickImage = () => {}
}) {
  const [modalOpen, setModalOpen] = useState(false)

  const handleOpen = () => {
    setModalOpen(true)
    userClickImage(post_id)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  const fallbackToOriginalImage = (ev) => {
    ev.target.src = media_url
  }

  return (
    <Modal
      onClose={handleClose}
      open={modalOpen}
      basic
      trigger={
        <div className={styles.tweetImageContainer}>
          <img
            src={media_medium_url}
            onError={fallbackToOriginalImage}
            onClick={handleOpen}
            className={styles.tweetImage}
            alt='Media'
          />
        </div>
      }
    >
      <Modal.Content image={true}>
        <Flexbox justifyContent='center' style={{ width: '100%' }}>
          <img
            src={media_url}
            alt='Media'
            style={{
              maxWidth: '90vw',
              maxHeight: '90vh',
              height: '100%',
              width: '100%',
              objectFit: 'contain'
            }}
          />
        </Flexbox>
      </Modal.Content>
    </Modal>
  )
}
