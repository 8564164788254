import React, { memo, useEffect, useState } from 'react'
import Flexbox from 'flexbox-react'
import PropTypes from 'prop-types'

const mql = window.matchMedia(`(min-width: 800px)`)

function Toolbar(props) {
  const [toolbarWidth, setToolbarWidth] = useState(
    props.isWebview ? '100%' : mql.matches ? 'calc(100% - 260px)' : '100%'
  )

  useEffect(() => {
    mql.addEventListener('change', toggleToolbar)

    return () => {
      mql.removeEventListener('change', toggleToolbar)
    }
  }, [])

  const toggleToolbar = () => {
    if (!props.isWebview) {
      if (mql.matches) {
        setToolbarWidth('calc(100% - 260px)')
      } else {
        setToolbarWidth('100%')
      }
    }
  }

  return (
    <div
      style={Object.assign(
        {
          zIndex: 10,
          backgroundColor: '#e3e3e3',
          borderBottom: '1px solid grey',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          top: props.isWebview ? 0 : 66,
          right: 0,
          position: 'fixed',
          minHeight: '60px',
          width: toolbarWidth,
          padding: '8px'
        },
        props.containerStyle
      )}
    >
      <Flexbox
        flexDirection='row'
        alignItems='center'
        justifyContent={props.shouldWrap ? 'center' : 'space-between'}
        width='100%'
        flexWrap={props.shouldWrap ? 'wrap' : 'nowrap'}
      >
        {props.leftItem && <ToolbarItem content={props.leftItem} {...props} />}
        {props.centerItem && (
          <ToolbarItem content={props.centerItem} {...props} />
        )}
        {props.rightItem && (
          <ToolbarItem content={props.rightItem} {...props} />
        )}
      </Flexbox>
    </div>
  )
}

const ToolbarItem = ({ content = '', resizeMode, toolbarItemStyle }) => {
  return resizeMode && !content ? null : (
    <div style={Object.assign({ margin: 2 }, toolbarItemStyle)}>{content}</div>
  )
}

Toolbar.propTypes = {
  leftItem: PropTypes.node,
  centerItem: PropTypes.node,
  rightItem: PropTypes.node,
  resizeMode: PropTypes.bool,
  shouldWrap: PropTypes.bool,
  toolbarStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default memo(Toolbar)
