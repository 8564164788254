import React, { useState } from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react'
import styles from './styles/MessageDeleteButton.css'
import { TweeterActionCreators } from '../redux/actions'
import { bindActionCreators } from 'redux'
import { StoreCtx } from '../Constants'
import { connect } from 'react-redux'

function MessageDeleteButton({
    post_id = 1,
    deleteMessage = () => {},
    getMessages = () => {}
}) {
    const [modalOpen, setModalOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleOpen = () => {
        setModalOpen(true)
    }

    const handleClose = () => {
        setModalOpen(false)
    }

    const deleteTweeterMessage = () => {
        //delete message api here
        deleteMessage(post_id, deleteMessageSuccess, deleteMessageFailure)
    }

    const deleteMessageSuccess = () => {
        handleClose()
        getMessages(false, 0)
    }

    const deleteMessageFailure = (message) => {
        setErrorMessage(message)
    }

    return (
        <Modal
            onClose={handleClose}
            open={modalOpen}
            trigger={
                <Icon
                    name='trash alternate outline'
                    onClick={handleOpen}
                    className={styles.delete_button}
                />
            }
        >
            <Modal.Header>
                Are you sure you wish to delete this message?
            </Modal.Header>
            <Modal.Content>
                <p>
                    If you delete this message it will no longer be viewable by
                    any users. This cannot be undone.
                </p>
                <p style={{ color: 'red' }}>{errorMessage}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button negative onClick={deleteTweeterMessage}>
                    Delete
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(TweeterActionCreators, dispatch)
}

export default connect(null, mapDispatchToProps, null, {
    context: StoreCtx
})(MessageDeleteButton)
