import React, { Component } from "react";
import { css, StyleSheet } from "aphrodite";
import { Button } from "semantic-ui-react";
import { pulse } from "react-animations";

export default class PulseButton extends Component {
    static defaultProps = {
        btnTitle: "",
        interval: 1000
    };

    constructor(props) {
        super(props);

        const pulseStyle = StyleSheet.create({
            pulse: {
                animationName: pulse,
                animationDuration: "1s"
            },
            pulse_button: {
                backgroundColor: "#666871",
                color: "white",
                padding: 0,
                paddingLeft: 10,
                paddingRight: 10,
                fontWeight: 100,
                fontSize: 32
            },
            styleProps: {
                ...this.props.style
            }
        });

        this.state = {
            pulseStyle,
            shouldPulse: false
        };

        this.pulseInterval = setInterval(
            this._togglePulse,
            this.props.interval
        );
    }
    componentWillUnmount() {
        clearInterval(this.pulseInterval);
    }

    _togglePulse = () => {
        this.setState({ shouldPulse: !this.state.shouldPulse });
    };

    render() {
        let pulseClass = css(
            this.state.pulseStyle.pulse_button,
            this.state.pulseStyle.styleProps,
            this.state.shouldPulse && this.state.pulseStyle.pulse
        );

        return (
            <Button
                className={pulseClass}
                onClick={this.props.onClick}
                {...this.props.buttonProps}
            >
                {this.props.btnTitle}
            </Button>
        );
    }
}
