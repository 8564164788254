import { executeBasicNetworkCall } from '../../lib/Utils'

export function userViewPost(post_id, success = () => {}, failure = () => {}) {
    let json = { post_id }
    json = JSON.stringify(json)

    executeBasicNetworkCall(
        'user_view_post',
        json,
        'application/json',
        'POST',
        null,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText)
                if (x.error) {
                    failure()
                } else {
                    success()
                }
            }
        }
    )
}

export function userLikePost(post_id, success = () => {}, failure = () => {}) {
    let json = { post_id }
    json = JSON.stringify(json)

    executeBasicNetworkCall(
        'user_like_post',
        json,
        'application/json',
        'POST',
        null,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText)
                if (x.error) {
                    failure()
                } else {
                    success()
                }
            }
        }
    )
}

export function userUnlikePost(
    post_id,
    success = () => {},
    failure = () => {}
) {
    let json = { post_id }
    json = JSON.stringify(json)

    executeBasicNetworkCall(
        'user_unlike_post',
        json,
        'application/json',
        'POST',
        null,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText)
                if (x.error) {
                    failure()
                } else {
                    success()
                }
            }
        }
    )
}

export function userImpressionPost(
    post_ids,
    success = () => {},
    failure = () => {}
) {
    let json = { post_ids }
    json = JSON.stringify(json)

    executeBasicNetworkCall(
        'user_impression_post',
        json,
        'application/json',
        'POST',
        null,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText)
                if (x.error) {
                    failure()
                } else {
                    success()
                }
            }
        }
    )
}

export function userClickImage(
    post_id,
    success = () => {},
    failure = () => {}
) {
    let json = { post_id }
    json = JSON.stringify(json)

    executeBasicNetworkCall(
        'user_click_image_post',
        json,
        'application/json',
        'POST',
        null,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText)
                if (x.error) {
                    failure()
                } else {
                    success()
                }
            }
        }
    )
}

export function userClickLink(post_id, success = () => {}, failure = () => {}) {
    let json = { post_id }
    json = JSON.stringify(json)

    executeBasicNetworkCall(
        'user_click_link_post',
        json,
        'application/json',
        'POST',
        null,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText)
                if (x.error) {
                    failure()
                } else {
                    success()
                }
            }
        }
    )
}
