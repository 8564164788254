import { executeBasicNetworkCall } from "@linxsystems/web-core";
import { store } from "../store";

export const UserType = {
    All: "all",
    Internal: "internal",
    External: "external",
    Unauthenticated: "unauthenticated",
};

/**
 *
 * @param category
 * @param action
 * @param label
 * @param value
 * @param source
 * @returns {null}
 */
export function recordAnalyticsEvent(
    category,
    action,
    label,
    value,
    token,
    source = "Web",
) {
    if (!category || category.length === 0 || !action || action.length === 0) {
        return null;
    }

    const params = {
        category,
        action,
        label,
        value,
        source,
        api_key: "abc123",
    };
    if (token !== undefined) {
        params.source = "Mobile";
        params.auth_token = token;
    }

    const getState = store.getState;

    executeBasicNetworkCall(
        "record_analytics",
        JSON.stringify(params),
        "application/json",
        "POST",
        getState,
    );
}

const today = new Date();
const oneDay = 86400 * 1000;
const thirtyDaysAgo = new Date(today.getTime() - 30 * oneDay).toISOString();

export function getCategories(
    callback = () => {},
    userType = UserType.All,
    startDate = thirtyDaysAgo,
    endDate = today.toISOString(),
) {
    return (dispatch, getState) => {
        const params = JSON.stringify({
            start_date: startDate,
            end_date: endDate,
            user_type: userType,
        });

        executeBasicNetworkCall(
            "get_categories",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x) {
                        callback(!x.error, x.message, x.data);
                    }
                }
            },
        );
    };
}

export function getActionsForCategories(
    callback = () => {},
    category_id,
    userType = UserType.All,
    startDate = thirtyDaysAgo,
    endDate = today.toISOString(),
) {
    return (dispatch, getState) => {
        const params = JSON.stringify({
            start_date: startDate,
            end_date: endDate,
            user_type: userType,
            category_id: category_id,
        });

        executeBasicNetworkCall(
            "get_actions_for_category",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x) {
                        callback(!x.error, x.message, x.data);
                    }
                }
            },
        );
    };
}

export function getLabelsForCategoryAndAction(
    callback = () => {},
    category_id,
    action_id,
    userType = UserType.All,
    startDate = thirtyDaysAgo,
    endDate = today.toISOString(),
) {
    return (dispatch, getState) => {
        const params = JSON.stringify({
            start_date: startDate,
            end_date: endDate,
            user_type: userType,
            category_id: category_id,
            action_id: action_id,
        });

        executeBasicNetworkCall(
            "get_labels_for_category_and_action",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x) {
                        callback(!x.error, x.message, x.data);
                    }
                }
            },
        );
    };
}

export function getValuesForCategoryAndActionAndLabel(
    callback = () => {},
    category_id,
    action_id,
    label,
    activePage,
    numRows,
    userType = UserType.All,
    startDate = thirtyDaysAgo,
    endDate = today.toISOString(),
) {
    return (dispatch, getState) => {
        const params = JSON.stringify({
            start_date: startDate,
            end_date: endDate,
            user_type: userType,
            category_id: category_id,
            action_id: action_id,
            label: label,
            active_page: activePage,
            num_rows: numRows,
        });

        executeBasicNetworkCall(
            "get_data_for_category_and_action_and_label",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x) {
                        callback(!x.error, x.message, x.data, x.total_pages);
                    }
                }
            },
        );
    };
}

export function getValuesDataForCSV(
    callback = () => {},
    category_id,
    action_id,
    label,
    userType = UserType.All,
    startDate = thirtyDaysAgo,
    endDate = today.toISOString(),
) {
    return (dispatch, getState) => {
        const params = JSON.stringify({
            start_date: startDate,
            end_date: endDate,
            user_type: userType,
            category_id: category_id,
            action_id: action_id,
            label: label,
        });

        executeBasicNetworkCall(
            "get_values_data_for_csv",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x) {
                        callback(!x.error, x.data);
                    }
                }
            },
        );
    };
}
