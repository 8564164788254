import Logo from '../assets/logos/basf.png'
import Icon from '../assets/icons/basf.svg'

export const GrowSmartLive = {
  logo: Logo.default,

  icon: Icon.default,

  LightestPrimaryColor: '#bcd49c',
  LighterPrimaryColor: '#a5c67d',
  LightPrimaryColor: '#8db85d',
  PrimaryColor: '#75AA3B',
  DarkPrimaryColor: '#699935',
  DarkerPrimaryColor: '#5d882f',
  DarkestPrimaryColor: '#517629',

  LightestSecondaryColor: '#797b83',
  LighterSecondaryColor: '#666871',
  LightSecondaryColor: '#53555f',
  SecondaryColor: '#40434E',
  DarkSecondaryColor: '#393c46',
  DarkerSecondaryColor: '#33353e',
  DarkestSecondaryColor: '#2c2e36'
}
