import React from 'react'
import Avatar from 'react-avatar'
import * as Themes from '../themes'

import styles from '../styles/InnercastAvatar.css'

import { connect } from 'react-redux'
import { StoreCtx } from '../Constants'

function InnercastAvatar({ overwriteSrc = false, ...props }) {
    const THEME = props.theme
    const HOST = props.host

    const getColor = () => {
        let color

        switch (props.group_id) {
            case 1:
                color = 'crimson'
                break
            case 2:
                color = 'maroon'
                break
            case 3:
                color = 'lightsalmon'
                break
            case 4:
                color = 'darkkhaki'
                break
            case 5:
                color = 'purple'
                break
            case 6:
                color = 'palevioletred'
                break
            case 7:
                color = Themes.getSecondaryLightThemeColor(THEME)
                break
            case 8:
                color = Themes.getPrimaryLightestThemeColor(THEME)
                break
            case 9:
                color = 'lightslategray'
                break
            default:
                color = Themes.getSecondaryThemeColor(THEME)
        }
        return color
    }

    const getStyle = () => {
        let style

        if (props.style) {
            style = props.style
            style.backgroundColor = 'rgb(91, 93, 102)'
        } else {
            style = { backgroundColor: 'rgb(91, 93, 102)' }
        }
        return style
    }

    const getSource = () => {
        let source = props.src
        if (source !== undefined && source !== null && source.length > 0) {
            if (!source.includes('http')) {
                source = HOST + '/media/' + source
                if (props.overwriteSrc) {
                    const regex = /\d+[.].*/
                    const thumbnailSrc = props.src.replace(
                        regex,
                        'thumbnail.jpeg'
                    )

                    if (thumbnailSrc !== '') {
                        source = HOST + '/media/' + thumbnailSrc
                    }
                }
            }
        } else {
            let viewerAvatars = props.viewerAvatars
            if (viewerAvatars) {
                switch (props.circle) {
                    case 'Grower':
                        source = viewerAvatars[0]
                        break
                    case 'Retailer':
                        source = viewerAvatars[1]
                        break
                    case 'Consultant':
                        source = viewerAvatars[2]
                        break
                    default:
                        break
                }
            }
        }
        return source
    }

    return (
        <Avatar
            src={getSource()}
            style={getStyle()}
            round
            size={props.size}
            name={props.name}
            color={getColor()}
            onClick={props.onClick}
            className={styles.innercastAvatar}
        />
    )
}

function mapStateToProps(state) {
    return {
        theme: state.core.theme,
        host: state.core.host
    }
}

export default connect(mapStateToProps, null, null, {
    context: StoreCtx
})(InnercastAvatar)
