import React from 'react'

export default function ComposeCameraSvg() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
            width='25'
            height='25'
        >
            <circle cx='256' cy='256' r='256' fill='#ffca28' />
            <g fill='#6d4c41'>
                <path d='M399.68 208.32c-8.832 0-16-7.168-16-16 0-17.632-14.336-32-32-32s-32 14.368-32 32c0 8.832-7.168 16-16 16s-16-7.168-16-16c0-35.296 28.704-64 64-64s64 28.704 64 64c0 8.864-7.168 16-16 16zM207.68 208.32c-8.832 0-16-7.168-16-16 0-17.632-14.368-32-32-32s-32 14.368-32 32c0 8.832-7.168 16-16 16s-16-7.168-16-16c0-35.296 28.704-64 64-64s64 28.704 64 64c0 8.864-7.168 16-16 16z' />
            </g>
            <path
                d='M437.696 294.688c-3.04-4-7.744-6.368-12.736-6.368H86.4a15.898 15.898 0 00-12.736 6.336 15.97 15.97 0 00-2.688 14.016C94.112 390.88 170.08 448.32 255.648 448.32s161.536-57.44 184.672-139.648a15.859 15.859 0 00-2.624-13.984z'
                fill='#fafafa'
            />
        </svg>
    )
}
