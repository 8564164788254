// CORE
export const SET_ENDPOINT_URLS = 'SET_ENDPOINT_URLS'
export const SET_BASENAME = 'SET_BASENAME'
export const SET_APPNAME = 'SET_APPNAME'
export const SET_THEME = 'SET_THEME'
export const SET_HOST = 'SET_HOST'
export const SET_AUTH_GROUP = 'SET_AUTH_GROUP'
export const SET_DEBUG = 'SET_DEBUG'

// ERROR
export const SET_ERROR = 'SET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

// SUCCESS
export const SET_SUCCESS = 'SET_SUCCESS'
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS'
