export const Wilson = {
    LightestPrimaryColor: "#e27f95",
    LighterPrimaryColor: "#d74c6a",
    LightPrimaryColor: "#cb1940",
    PrimaryColor: "#C6002B",
    DarkPrimaryColor: "#b20026",
    DarkerPrimaryColor: "#8a001e",
    DarkestPrimaryColor: "#630015",


    LightestSecondaryColor: "#797b83",
    LighterSecondaryColor: "#666871",
    LightSecondaryColor: "#53555f",
    SecondaryColor: "#40434E",
    DarkSecondaryColor: "#393c46",
    DarkerSecondaryColor: "#33353e",
    DarkestSecondaryColor: "#2c2e36"
};
