import React, { useState } from "react";
import Flexbox from "flexbox-react";
import { Dropdown, Icon, Pagination } from "semantic-ui-react";
import { useMedia } from "../lib/hooks";

export default function CustomPagination({
    activePage = 1,
    handlePageChange = () => {},
    totalPages = 1,
    dropdownOptions = [
        { key: 20, text: "20", value: 20 },
        { key: 25, text: "25", value: 25 },
        { key: 50, text: "50", value: 50 },
        { key: 100, text: "100", value: 100 },
    ],
    numRows = 20,
    setNumRows = () => {},
    showRowSelector = true,
}) {
    const showDropdown =
        showRowSelector && dropdownOptions.map((option) => option.value);
    const [dropdownValue, setDropdownValue] = useState(numRows);

    const ellipsisItem =
        totalPages > 3
            ? { content: <Icon name="ellipsis horizontal" />, icon: true }
            : null;

    const firstItem =
        totalPages > 3
            ? {
                  content: <Icon name="angle double left" />,
                  icon: true,
                  disabled: activePage === 1,
              }
            : null;

    const lastItem =
        totalPages > 3
            ? {
                  content: <Icon name="angle double right" />,
                  icon: true,
                  disabled: activePage === totalPages,
              }
            : null;

    const prevItem = {
        content: <Icon name="angle left" />,
        icon: true,
        disabled: activePage === 1,
    };

    const nextItem = {
        content: <Icon name="angle right" />,
        icon: true,
        disabled: activePage === totalPages,
    };

    const siblingRange = useMedia([`(max-width: 550px)`], [0], 1);
    const boundaryRange = useMedia([`(max-width: 450px)`], [0], 1);

    return (
        <Flexbox
            flexDirection="row"
            flexWrap="wrap-reverse"
            width="100%"
            justifyContent="space-around"
            alignItems="center">
            {showDropdown && (
                <Flexbox
                    id="num_rows"
                    justifyContent="center"
                    alignItems="center"
                    margin="10px">
                    <p style={{ marginRight: 10 }}>Number of rows</p>
                    <Dropdown
                        options={dropdownOptions}
                        value={dropdownValue}
                        onChange={(e, data) => {
                            setDropdownValue(data.value);
                            setNumRows(data.value);
                            handlePageChange(e, { activePage: 1 });
                        }}
                        selection
                        style={{ minWidth: "6em" }}
                    />
                </Flexbox>
            )}
            <Pagination
                id="custom_pagination"
                activePage={activePage}
                onPageChange={handlePageChange}
                ellipsisItem={ellipsisItem}
                firstItem={firstItem}
                lastItem={lastItem}
                prevItem={prevItem}
                nextItem={nextItem}
                totalPages={totalPages}
                pointing
                secondary
                siblingRange={siblingRange}
                boundaryRange={boundaryRange}
            />
        </Flexbox>
    );
}
