export const CirrusLife = {
  LightestPrimaryColor: '#a5a5a5',
  LighterPrimaryColor: '#818181',
  LightPrimaryColor: '#5d5d5d',
  PrimaryColor: '#4B4B4C',
  DarkPrimaryColor: '#434344',
  DarkerPrimaryColor: '#343435',
  DarkestPrimaryColor: '#252526',

  LightestSecondaryColor: '#4B4B4C',
  LighterSecondaryColor: '#434344',
  LightSecondaryColor: '#343435',
  SecondaryColor: '#252526',
  DarkSecondaryColor: '#212122',
  DarkerSecondaryColor: '#19191a',
  DarkestSecondaryColor: '#121213'
}
