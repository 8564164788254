import React, { memo } from 'react'
import { MultiSelectDropdown } from '@linxsystems/web-core'
import '@linxsystems/web-core/dist/index.css'

const HashtagDropdown = ({
    name,
    options = [],
    placeholder = 'Select',
    leftIcon = <i aria-hidden='true' className='caret right icon' />,
    setValues = () => {},
    values = [],
    dropdownStyle = undefined,
    onOpen = () => {},
    onClose = () => {},
    ...dropdownProps
}) => {
    return (
        <div
            className='ui left icon input'
            style={{ margin: 10, marginTop: 0 }}
        >
            <MultiSelectDropdown
                name={name}
                options={options}
                placeholder={placeholder}
                setValues={setValues}
                values={values}
                style={dropdownStyle}
                onOpen={onOpen}
                onClose={onClose}
                {...dropdownProps}
            />
            {leftIcon}
        </div>
    )
}

export default memo(HashtagDropdown)
