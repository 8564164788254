import React, { useState } from 'react'
import {
    Grid,
    Modal,
    Button,
    Header,
    Form,
    Loader,
    Dimmer
} from 'semantic-ui-react'
import { getPrimaryThemeColor } from '../themes'

import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions'
import { bindActionCreators } from 'redux'
import { StoreCtx } from '../Constants'

function UploadProfilePicture({
    prof_pic_modal_open = false,
    token = null,
    theme = 'innercast',
    changeMyDetails = () => {},
    close = () => {},
    createOrUpdateProfilePicture = () => {},
    refreshWholePage = () => {},
    uploadMediaToAzure = () => {}
}) {
    const [profilePictureFile, setProfilePictureFile] = useState(null)
    const [uploading, setUploading] = useState(false)

    const _profilePictureFileChanged = (e) => {
        const event = e.target
        setProfilePictureFile(event.files[0])
    }

    const _onSubmitProfilePicture = () => {
        if (profilePictureFile) {
            setUploading(true)

            uploadMediaToAzure(
                token,
                profilePictureFile,
                null,
                successCallback,
                'id',
                doneUploadingProfPic
            )
        }
    }

    const successCallback = (success, azure_media_id) => {
        if (success) {
            createOrUpdateProfilePicture(
                null,
                azure_media_id,
                doneUploadingProfPic,
                token,
                changeMyDetails
            )
        } else {
            doneUploadingProfPic()
        }
    }

    const doneUploadingProfPic = () => {
        close()
        setProfilePictureFile(null)
        setUploading(false)
        refreshWholePage()
    }

    const buttonOrUploading = uploading ? (
        <Dimmer active inverted inline='centered'>
            <Loader
                active
                inverted
                inline='centered'
                style={{ color: 'black' }}
            >
                Uploading Profile Picture...
            </Loader>
        </Dimmer>
    ) : (
        <Button
            style={{
                marginTop: 10,
                backgroundColor: getPrimaryThemeColor(theme),
                color: 'white'
            }}
            type='submit'
        >
            Upload
        </Button>
    )

    return (
        <Modal
            open={prof_pic_modal_open}
            onClose={close}
            size='small'
            style={{ position: 'static' }}
        >
            <Header content='Upload Profile Picture' />
            <Modal.Content>
                <Grid verticalAlign='middle' style={{ marginBottom: '0px' }}>
                    <Grid.Row columns={1}>
                        <Grid.Column style={{ maxHeight: '100%' }}>
                            <Form
                                onSubmit={_onSubmitProfilePicture}
                                style={{ height: '155px' }}
                            >
                                <h3>Profile Picture</h3>
                                <input
                                    type='file'
                                    name='companyLogo'
                                    accept='image/png, image/jpeg'
                                    onChange={_profilePictureFileChanged}
                                />
                                {buttonOrUploading}
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close}>Close</Button>
            </Modal.Actions>
        </Modal>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
    return {
        theme: state.core.theme
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    context: StoreCtx
})(UploadProfilePicture)
